/*
----------------
variables.scss
----------------

Wherever possible, try to use these variables rather than hardcoding things like
colors, font-sizes, etc.
*/

// Element colors
$ecolor1: #fff;
$ecolor2: #4a4a4a;
$ecolor3: #cccccc;
$ecolor4: #bbbbbb;
$ecolor5: #aaaaaa;

// Text colors
$tcolor1: $ecolor2;
$tcolor2: white;
$tcolor3: #ddd;
// $tcolor4: #c7ba9b;
$tcolor4: rgb(9, 87, 152);

// Button colors
$bcolor1: rgb(0, 123, 255);
$bcolor2: $ecolor2;

// Link colors
$lcolor1: $tcolor1;
$lcolor2: white;

// Container Width
$max_width: 1100px;
$s_container_width: 90%;
$m_container_width: 90%;
$l_container_width: 86%;
$xl_container_width: 86%;

// Header height
$header_height: 4rem; // Set this here so that <main> gets the same margin
$admin_bar_height: 2.7rem;
$admin_header_height: 12rem;

// Set media query breakpoints
$s_min: 0;       // s: Small, mobile
$m_min: 600px;   // m: Medium, tablet
$l_min: 992px;   // l: Large, Desktop
$xl_min: 1200px; // xl: Extra Large, Big Screen

// Let's also set max sizes which are useful in some media queries
$s_max: $m_min - 1;
$m_max: $l_min - 1;
$l_max: $xl_min - 1;

// Grid
$number_of_columns: 12;
$gutter_width: 2rem;
