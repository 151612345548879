/* Header
–––––––––––––––––––––––––––––––––––––––––––––––––– */
// .is-admin header {
//   top: $admin_bar_height;
// }

// NOTE: applying padding to main content area since header isn't fixed
.is-admin {
  padding-top: $admin_bar_height;
}



header {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: $ecolor1;
  //height: $header_height;
  z-index:89;
  @include drop-shadow;

  .collapsible-panel, .gallery-list-container {
    @include drop-shadow;
  }

  .shadow-nav {
    position:absolute;
    top: -1000px;
  }

  .hamburger {
    display:inline-block;
    padding: 0;
    border: none;
    width:30px;
    margin:15px;
    display: block;
    .ham-x span {
      background: $tcolor1;
    }
  }

  .services-dropdown {
    background: white;
    padding: 30px;
    background: #f6f6f6;
    .service-icons {
      margin-bottom:0px;
      .icon-container {
        border: 0;
        padding: 0;

      }
      .icon {
        width:30px;
        padding-bottom: 30px;
        margin: 0 10px;
        display: inline-block;
        vertical-align: middle;
      }
      .snip {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
      }
    }
  }

  a:not(.btn):not(.logo) {
    transition: color .3s;
    &:hover, &.active {
      color: $tcolor4;
    }
  }

  nav {
    display:flex;
    align-items: flex-start;
    flex-grow: 1;
    padding: 0 20px;
    padding-top: 20px;
    .logo {
      position:relative;
      padding:0;
      .editable-image {
        margin:0;
        width: 150px;
        height: 65px;
        @media (max-width: 500px){
          width: 200px;
        }
      }
    }
    .list-menu {
      min-height: 100%;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: inline-block;
      padding: 0;
      margin: 0 0 0 1px;
      vertical-align:middle;
      position: relative;
      .sub-menu {
        padding: 10px;
        background: white;
        position: absolute;
        top: 100%;
        min-width: 200px;
        display: none;
        text-align: left;
        z-index: 2;
        box-shadow: 0 0 2px 2px rgba(0,0,0,.1);
        li {
          display: block;
        }
      }
      &:hover {
        .sub-menu {
          display: block;
        }
      }
    }

    .menu {
      flex-grow: 1;
      text-align: center;

      .sub-menu a:not(.btn):not(.logo) {
        line-height: 3rem;
      }

      a {
        i {
          margin-left: 5px;
        }
        img {
          max-width:15px;
          margin-left: 5px;
          transform: translate(0px, 0px);
          transition: transform .5s;
        }
        &:hover {
          img {
            transform: translate(0px, 3px)
          }
        }
        &:not(.btn):not(.logo) {
          margin: 0;
          margin-right: 15px;
          display:block;
          line-height: 2rem;
          transition: border-bottom .5s;
          //height: calc(#{$header_height} - .3rem);

          // &:hover {
          //   &:not(.logo){
          //     border-bottom:.3rem solid $tcolor4;
          //   }
          // }
          // &.active {
          //   &:not(.logo){
          //     border-bottom:.3rem solid $tcolor1;
          //   }
          // }
        }
        &.btn {
          margin-left:10px;
        }
        color: $tcolor1;
        font-size: 1rem;
        text-decoration: none;
      }


      .collapsible-nav {
        .menu-link {
          border-top: 1px solid #ddd;
          a {
            display: inline-block;
          }
        }
        ul {

          display: block;
          list-style: none;
          li {
            padding: 0;
          }
          .sub-menu {
            text-align: left;
            position: relative;
            padding-left: 30px;
            top:0;
            left: 0;
            background: transparent;
          }
        }
        &.open {
          height:calc(100vh - #{$header_height});
          max-height: 100vh;
          overflow:scroll;
          padding: 20px;
          padding-bottom: 25vh
        }
        max-height:0px;
        overflow:hidden;
        transition: max-height .3s, padding .3s;
        position:absolute;
        background:white;
        width:100%;
        left:0;
        padding:0;
        li {
          display:block;
        }

        a {
          &:not(.btn):not(.logo) {
            padding: 3px 1em;
            padding-right: 4em;
            margin-left:40px;
          }
          &.btn {
            margin-left: 30px;
          }
        }
      }
    }
  }

  .search-menu {
    flex: 1;
  }

  .tools {
    display: flex;
    padding: 0 10px;
    align-items: center;
    .btn {
      margin: 0 10px;
      margin-left: 20px;
    }
    .icons {
      .fa {
        color: #999;
        font-size: 1.5em;
        margin: 0 10px;
      }
    }
    // @media only screen and (max-width:1000px){
    //   display: block;
    //   text-align: center;
    //   .icons {
    //     margin-top: 10px;
    //   }
    // }
  }
  .header-right {
    min-width: 150px;
    min-height: 65px;
  }

  .sub-nav {
    background: #eee;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      list-style: none;
      li, .dynamic-link-container {
        font-size: .9em;
        border-right: 2px solid #ccc;
        &:last-of-type {
          border-right: 0;
        }
        a {
          display: block;
          padding: 10px 30px;
        }
      }
    }
  }
}

nav.main-menu {
  padding-top: 0;
  a {
    font-size: 16px !important;
    padding: 10px 5px !important;
    margin: 0 !important;
    line-height: 30px !important;
  }
}

/* CLS Fixes */
@media screen and (min-width: 1200px) {
  header {
    min-height: 187px;

    .sub-nav {
      min-height: 47px;
    }
  }
}