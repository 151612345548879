/* Footer
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@mixin flex_justify($type){
  &.jc-#{$type} {
    justify-content: #{$type};
  }
}

.flex-grid {
  $spacing: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: $spacing/2;
  .cell {
    padding: $spacing/2;
    display:flex;
    flex-direction:column;
    .cell-wrapper {
      flex:1;
    }
  }

  &.margins {
    max-width: 1170px;
    margin: 0 auto;
  }

  // easy to use classes for justifying content within flexbox
  @include flex_justify(center);
  @include flex_justify(space-around);
  @include flex_justify(space-between);
  @include flex_justify(end);

  // setup column support up to 20 (arbitrary)
  $i: 1;
  @while $i <= 20 {
    $p: 100 / $i;
    &.cols-#{$i}>.cell{
      flex-basis: unquote($p + '%');
      max-width: unquote($p + '%');
    }
    $i: $i + 1;
  }

  // for demo
  .cell-wrapper {
    padding:10px;
    border:3px solid $ecolor1;
  }
}
