/*
----------------
grid.scss
----------------

Lifted from: http://materializecss.com/grid.html

Our standard grid has 12 columns. No matter the size of the browser, each of these columns will always have an equal width.

Remember when you are creating your layout that all columns must be contained inside a row and that you must add the col class to your inner divs to make them into columns

The container class is not strictly part of the grid but is important in laying out content. It allows you to center your page content.

For responsive, use col with classes s, m, l, xl (widths are set in variables.scss)

Example usage:

<div class="container">
  <div class="row">
    <div class="col s12"><p>s12</p></div>
    <div class="col s12 m4 l2"><p>s12 m4</p></div>
    <div class="col s12 m4 l8"><p>s12 m4</p></div>
    <div class="col s12 m4 l2"><p>s12 m4</p></div>
  </div>
  <div class="row">
    <div class="col s12 m6 l3"><p>s12 m6 l3</p></div>
    <div class="col s12 m6 l3"><p>s12 m6 l3</p></div>
    <div class="col s12 m6 l3"><p>s12 m6 l3</p></div>
    <div class="col s12 m6 l3"><p>s12 m6 l3</p></div>
  </div>
</div>
*/

// Let MaterializeCSS Grid use our variables set in variables.scss
$medium-and-up: (min-width: #{$m_min});
$large-and-up: (min-width: #{$l_min});
$extra-large-and-up: (min-width: #{$xl_min});
$num-cols: $number_of_columns;
$gutter-width: $gutter_width;

// NOTE: Only a few things were changed from the original MaterializeCSS:

// 1. Comments and variables were added here at the top.
// 2. Line 50 was changed to use the variable for max-width.
// 3. Lines 51, 55, and 60 were changed for variable width.
// 4. Added one more media query for extra large at line 63.

.container {
  margin: 0 auto;
  max-width: $max_width;
  //width: $s_container_width;
}
@media #{$medium-and-up} {
  .container {
    width: $m_container_width;
  }
}
@media #{$large-and-up} {
  .container {
    width: $l_container_width;
  }
}
@media #{$extra-large-and-up} {
  .container {
    width: $xl_container_width;
  }
}
.container .row {
  //margin-left: (-1 * $gutter-width / 2);
  //margin-right: (-1 * $gutter-width / 2);
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }
  &.no-pad-bot {
    padding-bottom: 0;
  }
  &.no-pad-top {
    padding-top: 0;
  }
}


// Mixins to eliminate code repitition
@mixin reset-offset {
  //margin-left: auto;
  left: auto;
  right: auto;
}
@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }
  &.pull-#{$size}#{$i} {
    right: $perc;
  }
  &.push-#{$size}#{$i} {
    left: $perc;
  }
}


.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    //float:left;

    box-sizing: border-box;
    padding: $gutter-width / 2;
    min-height: 1px;
    width: 100%;

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.s#{$i} {
        width: $perc;
        @include reset-offset;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("s", $i, $perc);
      $i: $i + 1;
    }

    @media #{$medium-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.m#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("m", $i, $perc);
        $i: $i + 1;
      }

      body {display:none;}
    }

    @media #{$large-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.l#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("l", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$extra-large-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.xl#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("xl", $i, $perc);
        $i: $i + 1;
      }
    }
  }
}
