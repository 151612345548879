.skip-link {
  left: -9999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

.skip-link:focus,
.skip-link:active {
  color: #263F8F;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  width: 150px;
  height: auto;
  overflow: auto;
  margin: 10px auto;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #263F8F;
  text-align: center;
  font-size: 15px;
  z-index: 999;

  &:hover {
    color: #3B65EF;
  }
}