/* partial-hero
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.hero {
  position:relative;
  min-height:90vh;
  padding: 15px;
  display:flex;
  justify-content:center;
  flex-direction:column;
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  //background-color:#ddd;

  &.half {
    min-height: 28vh
  }

  *:not(.backdrop):not(.overlay):not(.material-icons) {
    position:relative;
    z-index:1;
  }

  .material-icons {
    z-index: 1;
  }

  h1 {
    font-size: 6rem;
  }
  h1, h2, h3 {
    text-align:center;
    margin:10px;
    color:white;
    text-shadow: 0 0 4px rgba(0,0,0,.4);
  }

  @media (max-width: 812px) {
    h1 {
      font-size: 2.4rem;
    }
  }
}
