main {
  position: relative;
}

p {
  color: #646f79;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a,
.h1 > a, .h2 > a, .h3 > a, .h4 > a, .h5 > a, .h6 > a {
  color: #151b26;
  font-weight: 400;
}

h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover,
.h1 > a:hover, .h2 > a:hover, .h3 > a:hover, .h4 > a:hover, .h5 > a:hover, .h6 > a:hover {
  color: #1ca766;
}

h1.text-white > a, h2.text-white > a, h3.text-white > a, h4.text-white > a, h5.text-white > a, h6.text-white > a,
.h1.text-white > a, .h2.text-white > a, .h3.text-white > a, .h4.text-white > a, .h5.text-white > a, .h6.text-white > a {
  color: #fff;
}

h6,
.h6 {
  font-weight: 500;
}

figure {
  margin-bottom: 0;
}

object {
  margin-bottom: -0.3125rem;
}

/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: #fff;
  background-color: #263F8F;
}

::selection {
  color: #fff;
  background-color: #263F8F;
}

.bg-primary ::-moz-selection {
  color: #263F8F;
  background-color: #fff;
}

.bg-primary ::selection {
  color: #263F8F;
  background-color: #fff;
}

/*------------------------------------
  Accessibility
------------------------------------*/

*:focus-visible {
  outline-color: #2684FF;
  outline-width: 3px;
  outline-style: solid;
}
input:focus, button:focus {
  outline-color: #2684FF;
  outline-width: 3px;
  outline-style: solid;
}

// :focus,
// a:focus,
// button:focus {
//   outline: 0;
// }

// .form-control:focus {
//   box-shadow: 0 0 0 0 transparent;
// }

// .btn:focus, .btn.focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
//   box-shadow: 0 0 0 0 transparent;
// }

// .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus,
// .show > .btn.dropdown-toggle:focus {
//   box-shadow: 0 0 0 0 transparent;
// }

// .page-link:focus {
//   box-shadow: 0 0 0 0 transparent;
// }

// .custom-control-input:checked ~ .custom-control-label::before,
// .custom-control-input:focus ~ .custom-control-label::before,
// .custom-control-input:active ~ .custom-control-label::before {
//   box-shadow: 0 0 0 0 transparent;
// }

// .custom-file-input:focus ~ .custom-file-label {
//   box-shadow: 0 0 0 0 transparent;
// }

/*------------------------------------
  List Group
------------------------------------*/
.list-group {
  list-style: none;
}

.list-group .list-group {
  margin-left: 1rem;
}

.list-group .list-group-item {
  font-size: 0.875rem;
}

.list-group .active > .list-group-item {
  color: #263F8F;
}

/* Border Radius */
.list-group-rounded-0 .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-rounded-0 .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* Transparent */
.list-group-transparent .list-group-item {
  color: #fff;
  border-color: transparent;
  background-color: transparent;
}

.list-group-transparent .list-group-item[href] {
  color: rgba(255, 255, 255, 0.7);
}

.list-group-transparent .list-group-item[href]:hover {
  color: #fff;
}

.list-group-transparent.list-group-striped .list-group-item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Flush */
.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.list-group-flush .list-group-item.active {
  color: #263F8F;
  background-color: transparent;
  border-color: transparent;
}

/* Borderless */
.list-group-borderless .list-group-item {
  border: none;
}

/* Striped */
.list-group-striped .list-group-item:nth-child(even) {
  background-color: #f5f8fb;
}

/*------------------------------------
  Header Default
------------------------------------*/
.u-header {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 101;
}

.u-header__section {
  position: relative;
  z-index: 1;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
  border-bottom: 1px solid #e3e6f0;
}

.u-header__hamburger {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

/* Navbar */
.u-header__navbar {
  padding: 0;
}

.u-header__navbar-brand {
  width: 9.375rem;
}

.u-header__navbar-brand-mobile {
  width: 3.125rem;
}

.u-header__navbar-nav {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.u-header__navbar-icon {
  padding-top: 1.3125rem;
  padding-bottom: 1.3125rem;
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
}

.u-header__navbar-icon:last-child {
  padding-right: 0;
}

.u-header__navbar-icon-wrapper {
  margin-left: auto;
}

/* Nav Item */
.u-header__nav-item:hover .u-header__nav-link, .u-header__nav-item:focus .u-header__nav-link {
  color: #263F8F;
}

/* Nav Link */
.u-header__nav-link {
  color: #5a5757;
  font-size: 16px;
  font-weight: 400;
}

.u-header__nav-link-icon {
  font-size: 0.625rem;
  margin-left: 0.25rem;
  transition: 0.3s;
}

.u-header .active > .u-header__nav-link {
  color: #263F8F;
}

/* Sub Menu */
.u-header__sub-menu-title {
  display: block;
  color: #151b26;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
}

.u-header__sub-menu-nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  font-size: 0.875rem;
  color: #5a5757;
}

.u-header__sub-menu-nav-link-icon {
  font-size: 0.625rem;
}

.u-header__sub-menu .active > .u-header__sub-menu-nav-link {
  color: #263F8F;
}

/* Search */
.u-header__search-addon {
  border: none;
}

.u-header__search-addon-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Header Fix Top */
.u-header--fix-top {
  position: fixed;
}

.u-header.u-header--fix-top[data-effect-compensation] {
  transition: none;
}

/*------------------------------------
  Header Helpers
------------------------------------*/
.u-header--invisible {
  display: none;
}

.u-header--moved-up {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
}

.u-header--faded {
  opacity: 0;
  visibility: hidden;
}

.u-header__section--hidden {
  position: relative;
}

.u-header[data-header-fix-effect] {
  transition: 0.3s ease;
}

.u-header.u-header--untransitioned {
  transition: none;
}

.u-header.js-header-fix-moment {
  position: fixed;
  top: 0;
  bottom: auto;
}

/*------------------------------------
  Transparent Header
------------------------------------*/
@media (min-width: 992px) {
  .u-header--bg-transparent:not(.js-header-fix-moment):not(.u-scrolled) .u-header__section {
    background-color: transparent;
    border-color: transparent;
  }
  .u-header--bg-transparent.u-header--bordered:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__section,
  .u-header--bg-transparent.u-header--bordered:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__navbar-brand,
  .u-header--bg-transparent.u-header--bordered:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item-user,
  .u-header--bg-transparent.u-header--bordered:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__navbar-v-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--bg-transparent .u-header__navbar-brand-on-scroll {
    display: none;
  }
  .u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-default, .u-header--bg-transparent.u-scrolled .u-header__navbar-brand-default {
    display: none;
  }
  .u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-on-scroll, .u-header--bg-transparent.u-scrolled .u-header__navbar-brand-on-scroll {
    display: inline-block;
  }
}

@media (max-width: 991px) {
  .u-header--bg-transparent .u-header__navbar-brand-default {
    display: none;
  }
}

/*------------------------------------
  Header White Nav Links
------------------------------------*/
@media (min-width: 992px) {
  .u-header--white-nav-links .u-header__nav-link,
  .u-header--white-nav-links [class*="btn-text-"] {
    transition: 0.3s;
  }
  .u-header--white-nav-links .u-header__nav-link-icon {
    transition: 0s;
  }
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item:hover .u-header__nav-link {
    color: #fff;
  }
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-link,
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item-user-name,
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) [class*="btn-text-"] {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-link:hover,
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item-user-name:hover,
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) [class*="btn-text-"]:hover {
    color: #fff;
  }
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item-user {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

/*------------------------------------
  Default Navbar Expand
------------------------------------*/
.navbar-expand {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.u-header--bordered .navbar-expand .u-header__navbar-brand {
  border-right: 1px solid #e3e6f0;
  margin-right: 1.375rem;
}

.u-header--bordered .navbar-expand .u-header__navbar-v-divider {
  border-left: 0.0625rem solid #e3e6f0;
  margin-left: 1.375rem;
}

.u-header--modern .navbar-expand .u-header__hamburger {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: none;
  -ms-flex-order: 1;
      order: 1;
}

.u-header--modern .navbar-expand .u-header__navbar-brand-wrapper {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: none;
  -ms-flex-order: 1;
      order: 1;
}

.u-header--modern .navbar-expand .u-header__navbar-collapse {
  -ms-flex-order: 2;
      order: 2;
}

.u-header--modern .navbar-expand .u-header__secondary-nav {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: none;
  -ms-flex-order: 3;
      order: 3;
}

.navbar-expand .u-header__navbar-brand {
  padding-top: 1rem;
  padding-bottom: 0.9375rem;
  padding-right: 1rem;
}

.navbar-expand .u-header__navbar-nav {
  -ms-flex-align: center;
      align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: auto;
}

.navbar-expand .u-header__navbar-nav .u-header__nav-link {
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
  padding-right: 0.875rem;
  padding-left: 0.875rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu {
  border: 1px solid #e3e6f0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 8px 20px rgba(100, 111, 121, 0.1);
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-list-item {
  padding: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-link {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-offset {
  margin-top: -1.0625rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
  padding: 0;
}

.navbar-expand .u-header__mega-menu-list {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
  list-style: none;
}

.navbar-expand .u-header__mega-menu-col {
  padding-left: 2rem;
}

.navbar-expand .u-header__mega-menu-col:first-child {
  padding-left: 1.5rem;
}

.navbar-expand .u-header__mega-menu-col:not(:last-child) {
  border-right: 1px solid #e3e6f0;
}

.navbar-expand .u-header__mega-menu-wrapper-v1 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.navbar-expand .u-header__nav-item-btn {
  padding-left: 1rem;
}

.navbar-expand .u-header__nav-item-user {
  background-color: #f5f8fb;
  border-left: 1px solid #e3e6f0;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-right: 1rem;
}

.navbar-expand .u-header__nav-item-user-name {
  color: #646f79;
}

.navbar-expand .u-header__nav-item-user-name:hover {
  color: #263F8F;
}

.navbar-expand .u-header__nav-item-user + .u-header__navbar-v-divider {
  margin-left: 0;
}

.navbar-expand .u-header__navbar-v-divider {
  padding-left: 0.875rem;
}

.navbar-expand .u-header__search {
  position: absolute;
  right: 0;
  z-index: 102;
  width: 23.125rem;
  background-color: #fff;
  border: 1px solid #e3e6f0;
  box-shadow: 0 0 25px rgba(21, 27, 38, 0.1);
  padding: 1rem;
  border-radius: 0;
  margin-top: 1.3125rem;
  transition: none;
}

.navbar-expand .u-header__search.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-header--left-aligned-nav .navbar-expand .u-header__navbar-nav {
  width: 100%;
  margin-left: 0;
}

.u-header--left-aligned-nav .navbar-expand .u-header__nav-item-btn {
  margin-left: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .u-header--bordered .navbar-expand-sm .u-header__navbar-brand {
    border-right: 1px solid #e3e6f0;
    margin-right: 1.375rem;
  }
  .u-header--bordered .navbar-expand-sm .u-header__navbar-v-divider {
    border-left: 0.0625rem solid #e3e6f0;
    margin-left: 1.375rem;
  }
  .u-header--modern .navbar-expand-sm .u-header__hamburger {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-brand-wrapper {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-collapse {
    -ms-flex-order: 2;
        order: 2;
  }
  .u-header--modern .navbar-expand-sm .u-header__secondary-nav {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 3;
        order: 3;
  }
  .navbar-expand-sm .u-header__navbar-brand {
    padding-top: 1rem;
    padding-bottom: 0.9375rem;
    padding-right: 1rem;
  }
  .navbar-expand-sm .u-header__navbar-nav {
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: auto;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu {
    border: 1px solid #e3e6f0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 8px 20px rgba(100, 111, 121, 0.1);
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-list-item {
    padding: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-offset {
    margin-top: -1.0625rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-sm .u-header__mega-menu-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style: none;
  }
  .navbar-expand-sm .u-header__mega-menu-col {
    padding-left: 2rem;
  }
  .navbar-expand-sm .u-header__mega-menu-col:first-child {
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__mega-menu-col:not(:last-child) {
    border-right: 1px solid #e3e6f0;
  }
  .navbar-expand-sm .u-header__mega-menu-wrapper-v1 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__nav-item-btn {
    padding-left: 1rem;
  }
  .navbar-expand-sm .u-header__nav-item-user {
    background-color: #f5f8fb;
    border-left: 1px solid #e3e6f0;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-right: 1rem;
  }
  .navbar-expand-sm .u-header__nav-item-user-name {
    color: #646f79;
  }
  .navbar-expand-sm .u-header__nav-item-user-name:hover {
    color: #263F8F;
  }
  .navbar-expand-sm .u-header__nav-item-user + .u-header__navbar-v-divider {
    margin-left: 0;
  }
  .navbar-expand-sm .u-header__navbar-v-divider {
    padding-left: 0.875rem;
  }
  .navbar-expand-sm .u-header__search {
    position: absolute;
    right: 0;
    z-index: 102;
    width: 23.125rem;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    box-shadow: 0 0 25px rgba(21, 27, 38, 0.1);
    padding: 1rem;
    border-radius: 0;
    margin-top: 1.3125rem;
    transition: none;
  }
  .navbar-expand-sm .u-header__search.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .u-header--left-aligned-nav .navbar-expand-sm .u-header__navbar-nav {
    width: 100%;
    margin-left: 0;
  }
  .u-header--left-aligned-nav .navbar-expand-sm .u-header__nav-item-btn {
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .u-header--bordered .navbar-expand-md .u-header__navbar-brand {
    border-right: 1px solid #e3e6f0;
    margin-right: 1.375rem;
  }
  .u-header--bordered .navbar-expand-md .u-header__navbar-v-divider {
    border-left: 0.0625rem solid #e3e6f0;
    margin-left: 1.375rem;
  }
  .u-header--modern .navbar-expand-md .u-header__hamburger {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-brand-wrapper {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-collapse {
    -ms-flex-order: 2;
        order: 2;
  }
  .u-header--modern .navbar-expand-md .u-header__secondary-nav {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 3;
        order: 3;
  }
  .navbar-expand-md .u-header__navbar-brand {
    padding-top: 1rem;
    padding-bottom: 0.9375rem;
    padding-right: 1rem;
  }
  .navbar-expand-md .u-header__navbar-nav {
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: auto;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu {
    border: 1px solid #e3e6f0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 8px 20px rgba(100, 111, 121, 0.1);
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-list-item {
    padding: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-offset {
    margin-top: -1.0625rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-md .u-header__mega-menu-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style: none;
  }
  .navbar-expand-md .u-header__mega-menu-col {
    padding-left: 2rem;
  }
  .navbar-expand-md .u-header__mega-menu-col:first-child {
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__mega-menu-col:not(:last-child) {
    border-right: 1px solid #e3e6f0;
  }
  .navbar-expand-md .u-header__mega-menu-wrapper-v1 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__nav-item-btn {
    padding-left: 1rem;
  }
  .navbar-expand-md .u-header__nav-item-user {
    background-color: #f5f8fb;
    border-left: 1px solid #e3e6f0;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-right: 1rem;
  }
  .navbar-expand-md .u-header__nav-item-user-name {
    color: #646f79;
  }
  .navbar-expand-md .u-header__nav-item-user-name:hover {
    color: #263F8F;
  }
  .navbar-expand-md .u-header__nav-item-user + .u-header__navbar-v-divider {
    margin-left: 0;
  }
  .navbar-expand-md .u-header__navbar-v-divider {
    padding-left: 0.875rem;
  }
  .navbar-expand-md .u-header__search {
    position: absolute;
    right: 0;
    z-index: 102;
    width: 23.125rem;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    box-shadow: 0 0 25px rgba(21, 27, 38, 0.1);
    padding: 1rem;
    border-radius: 0;
    margin-top: 1.3125rem;
    transition: none;
  }
  .navbar-expand-md .u-header__search.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .u-header--left-aligned-nav .navbar-expand-md .u-header__navbar-nav {
    width: 100%;
    margin-left: 0;
  }
  .u-header--left-aligned-nav .navbar-expand-md .u-header__nav-item-btn {
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .u-header--bordered .navbar-expand-lg .u-header__navbar-brand {
    border-right: 1px solid #e3e6f0;
    margin-right: 1.375rem;
  }
  .u-header--bordered .navbar-expand-lg .u-header__navbar-v-divider {
    border-left: 0.0625rem solid #e3e6f0;
    margin-left: 1.375rem;
  }
  .u-header--modern .navbar-expand-lg .u-header__hamburger {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-brand-wrapper {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-collapse {
    -ms-flex-order: 2;
        order: 2;
  }
  .u-header--modern .navbar-expand-lg .u-header__secondary-nav {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 3;
        order: 3;
  }
  .navbar-expand-lg .u-header__navbar-brand {
    padding-top: 1rem;
    padding-bottom: 0.9375rem;
    padding-right: 1rem;
  }
  .navbar-expand-lg .u-header__navbar-nav {
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: auto;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
    border: 1px solid #e3e6f0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 8px 20px rgba(100, 111, 121, 0.1);
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-list-item {
    padding: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-offset {
    margin-top: -1.0625rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-lg .u-header__mega-menu-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style: none;
  }
  .navbar-expand-lg .u-header__mega-menu-col {
    padding-left: 2rem;
  }
  .navbar-expand-lg .u-header__mega-menu-col:first-child {
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__mega-menu-col:not(:last-child) {
    border-right: 1px solid #e3e6f0;
  }
  .navbar-expand-lg .u-header__mega-menu-wrapper-v1 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__nav-item-btn {
    padding-left: 1rem;
  }
  .navbar-expand-lg .u-header__nav-item-user {
    background-color: #f5f8fb;
    border-left: 1px solid #e3e6f0;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-right: 1rem;
  }
  .navbar-expand-lg .u-header__nav-item-user-name {
    color: #646f79;
  }
  .navbar-expand-lg .u-header__nav-item-user-name:hover {
    color: #263F8F;
  }
  .navbar-expand-lg .u-header__nav-item-user + .u-header__navbar-v-divider {
    margin-left: 0;
  }
  .navbar-expand-lg .u-header__navbar-v-divider {
    padding-left: 0.875rem;
  }
  .navbar-expand-lg .u-header__search {
    position: absolute;
    right: 0;
    z-index: 102;
    width: 23.125rem;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    box-shadow: 0 0 25px rgba(21, 27, 38, 0.1);
    padding: 1rem;
    border-radius: 0;
    margin-top: 1.3125rem;
    transition: none;
  }
  .navbar-expand-lg .u-header__search.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .u-header--left-aligned-nav .navbar-expand-lg .u-header__navbar-nav {
    width: 100%;
    margin-left: 0;
  }
  .u-header--left-aligned-nav .navbar-expand-lg .u-header__nav-item-btn {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .u-header--bordered .navbar-expand-xl .u-header__navbar-brand {
    border-right: 1px solid #e3e6f0;
    margin-right: 1.375rem;
  }
  .u-header--bordered .navbar-expand-xl .u-header__navbar-v-divider {
    border-left: 0.0625rem solid #e3e6f0;
    margin-left: 1.375rem;
  }
  .u-header--modern .navbar-expand-xl .u-header__hamburger {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-brand-wrapper {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-collapse {
    -ms-flex-order: 2;
        order: 2;
  }
  .u-header--modern .navbar-expand-xl .u-header__secondary-nav {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex-order: 3;
        order: 3;
  }
  .navbar-expand-xl .u-header__navbar-brand {
    padding-top: 1rem;
    padding-bottom: 0.9375rem;
    padding-right: 1rem;
  }
  .navbar-expand-xl .u-header__navbar-nav {
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: auto;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu {
    border: 1px solid #e3e6f0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 8px 20px rgba(100, 111, 121, 0.1);
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-list-item {
    padding: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-offset {
    margin-top: -1.0625rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-xl .u-header__mega-menu-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style: none;
  }
  .navbar-expand-xl .u-header__mega-menu-col {
    padding-left: 2rem;
  }
  .navbar-expand-xl .u-header__mega-menu-col:first-child {
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__mega-menu-col:not(:last-child) {
    border-right: 1px solid #e3e6f0;
  }
  .navbar-expand-xl .u-header__mega-menu-wrapper-v1 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__nav-item-btn {
    padding-left: 1rem;
  }
  .navbar-expand-xl .u-header__nav-item-user {
    background-color: #f5f8fb;
    border-left: 1px solid #e3e6f0;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-right: 1rem;
  }
  .navbar-expand-xl .u-header__nav-item-user-name {
    color: #646f79;
  }
  .navbar-expand-xl .u-header__nav-item-user-name:hover {
    color: #263F8F;
  }
  .navbar-expand-xl .u-header__nav-item-user + .u-header__navbar-v-divider {
    margin-left: 0;
  }
  .navbar-expand-xl .u-header__navbar-v-divider {
    padding-left: 0.875rem;
  }
  .navbar-expand-xl .u-header__search {
    position: absolute;
    right: 0;
    z-index: 102;
    width: 23.125rem;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    box-shadow: 0 0 25px rgba(21, 27, 38, 0.1);
    padding: 1rem;
    border-radius: 0;
    margin-top: 1.3125rem;
    transition: none;
  }
  .navbar-expand-xl .u-header__search.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .u-header--left-aligned-nav .navbar-expand-xl .u-header__navbar-nav {
    width: 100%;
    margin-left: 0;
  }
  .u-header--left-aligned-nav .navbar-expand-xl .u-header__nav-item-btn {
    margin-left: auto;
  }
}

@media (max-width: 575px) {
  .u-header--modern .navbar-expand-sm.u-header__navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-header--modern .navbar-expand-sm .u-header__hamburger {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-brand-wrapper {
    text-align: center;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    -ms-flex-order: 2;
        order: 2;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-collapse {
    -ms-flex-order: 4;
        order: 4;
  }
  .u-header--modern .navbar-expand-sm .u-header__secondary-nav {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
    -ms-flex-order: 2;
        order: 2;
  }
  .navbar-expand-sm.u-header__navbar {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .navbar-expand-sm .u-header__nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-sm .u-header__nav-link-icon {
    margin-left: auto;
  }
  .navbar-expand-sm .hs-mega-menu-opened .u-header__nav-link-icon,
  .navbar-expand-sm .hs-sub-menu-opened .u-header__nav-link-icon {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-sm .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
    transition: 0.3s;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-sm .u-header__sub-menu {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-sm .u-header__sub-menu-list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__mega-menu-list {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.375rem;
  }
  .navbar-expand-sm .u-header__nav-item-btn {
    padding-top: 1rem;
    padding-bottom: 1.375rem;
  }
  .navbar-expand-sm .u-header__navbar-v-divider {
    border-top: 0.0625rem solid #e3e6f0;
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm .u-header__search {
    -webkit-animation-duration: 0ms !important;
            animation-duration: 0ms !important;
  }
  .navbar-expand-sm .u-header__search-toggle {
    display: none;
  }
}

@media (max-width: 767px) {
  .u-header--modern .navbar-expand-md.u-header__navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-header--modern .navbar-expand-md .u-header__hamburger {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-brand-wrapper {
    text-align: center;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    -ms-flex-order: 2;
        order: 2;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-collapse {
    -ms-flex-order: 4;
        order: 4;
  }
  .u-header--modern .navbar-expand-md .u-header__secondary-nav {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
    -ms-flex-order: 2;
        order: 2;
  }
  .navbar-expand-md.u-header__navbar {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .navbar-expand-md .u-header__nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-md .u-header__nav-link-icon {
    margin-left: auto;
  }
  .navbar-expand-md .hs-mega-menu-opened .u-header__nav-link-icon,
  .navbar-expand-md .hs-sub-menu-opened .u-header__nav-link-icon {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-md .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
    transition: 0.3s;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-md .u-header__sub-menu {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-md .u-header__sub-menu-list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__mega-menu-list {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.375rem;
  }
  .navbar-expand-md .u-header__nav-item-btn {
    padding-top: 1rem;
    padding-bottom: 1.375rem;
  }
  .navbar-expand-md .u-header__navbar-v-divider {
    border-top: 0.0625rem solid #e3e6f0;
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md .u-header__search {
    -webkit-animation-duration: 0ms !important;
            animation-duration: 0ms !important;
  }
  .navbar-expand-md .u-header__search-toggle {
    display: none;
  }
}

@media (max-width: 991px) {
  .u-header--modern .navbar-expand-lg.u-header__navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-header--modern .navbar-expand-lg .u-header__hamburger {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-brand-wrapper {
    text-align: center;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    -ms-flex-order: 2;
        order: 2;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-collapse {
    -ms-flex-order: 4;
        order: 4;
  }
  .u-header--modern .navbar-expand-lg .u-header__secondary-nav {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
    -ms-flex-order: 2;
        order: 2;
  }
  .navbar-expand-lg.u-header__navbar {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .navbar-expand-lg .u-header__nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-lg .u-header__nav-link-icon {
    margin-left: auto;
  }
  .navbar-expand-lg .hs-mega-menu-opened .u-header__nav-link-icon,
  .navbar-expand-lg .hs-sub-menu-opened .u-header__nav-link-icon {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-lg .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
    transition: 0.3s;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-lg .u-header__sub-menu {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-lg .u-header__sub-menu-list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__mega-menu-list {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.375rem;
  }
  .navbar-expand-lg .u-header__nav-item-btn {
    padding-top: 1rem;
    padding-bottom: 1.375rem;
  }
  .navbar-expand-lg .u-header__navbar-v-divider {
    border-top: 0.0625rem solid #e3e6f0;
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg .u-header__search {
    -webkit-animation-duration: 0ms !important;
            animation-duration: 0ms !important;
  }
  .navbar-expand-lg .u-header__search-toggle {
    display: none;
  }
}

@media (max-width: 1199px) {
  .u-header--modern .navbar-expand-xl.u-header__navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-header--modern .navbar-expand-xl .u-header__hamburger {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
    -ms-flex-order: 1;
        order: 1;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-brand-wrapper {
    text-align: center;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    -ms-flex-order: 2;
        order: 2;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-collapse {
    -ms-flex-order: 4;
        order: 4;
  }
  .u-header--modern .navbar-expand-xl .u-header__secondary-nav {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
    -ms-flex-order: 2;
        order: 2;
  }
  .navbar-expand-xl.u-header__navbar {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .navbar-expand-xl .u-header__nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-xl .u-header__nav-link-icon {
    margin-left: auto;
  }
  .navbar-expand-xl .hs-mega-menu-opened .u-header__nav-link-icon,
  .navbar-expand-xl .hs-sub-menu-opened .u-header__nav-link-icon {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-xl .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
    transition: 0.3s;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-xl .u-header__sub-menu {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-xl .u-header__sub-menu-list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__mega-menu-list {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.375rem;
  }
  .navbar-expand-xl .u-header__nav-item-btn {
    padding-top: 1rem;
    padding-bottom: 1.375rem;
  }
  .navbar-expand-xl .u-header__navbar-v-divider {
    border-top: 0.0625rem solid #e3e6f0;
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl .u-header__search {
    -webkit-animation-duration: 0ms !important;
            animation-duration: 0ms !important;
  }
  .navbar-expand-xl .u-header__search-toggle {
    display: none;
  }
}

.u-header--modern .navbar-expand.u-header__navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.u-header--modern .navbar-expand .u-header__hamburger {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
  -ms-flex-order: 1;
      order: 1;
}

.u-header--modern .navbar-expand .u-header__navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
}

.u-header--modern .navbar-expand .u-header__navbar-brand-wrapper {
  text-align: center;
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
  -ms-flex-order: 2;
      order: 2;
}

.u-header--modern .navbar-expand .u-header__navbar-collapse {
  -ms-flex-order: 4;
      order: 4;
}

.u-header--modern .navbar-expand .u-header__secondary-nav {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
  -ms-flex-order: 2;
      order: 2;
}

.navbar-expand.u-header__navbar {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.navbar-expand .u-header__nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.navbar-expand .u-header__nav-link-icon {
  margin-left: auto;
}

.navbar-expand .hs-mega-menu-opened .u-header__nav-link-icon,
.navbar-expand .hs-sub-menu-opened .u-header__nav-link-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.navbar-expand .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
  transition: 0.3s;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.navbar-expand .u-header__sub-menu {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
  -webkit-animation: none;
          animation: none;
}

.navbar-expand .u-header__sub-menu-list-item {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__mega-menu-list {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.375rem;
}

.navbar-expand .u-header__nav-item-btn {
  padding-top: 1rem;
  padding-bottom: 1.375rem;
}

.navbar-expand .u-header__navbar-v-divider {
  border-top: 0.0625rem solid #e3e6f0;
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .u-header__search {
  -webkit-animation-duration: 0ms !important;
          animation-duration: 0ms !important;
}

.navbar-expand .u-header__search-toggle {
  display: none;
}

@media (min-width: 576px) {
  .u-header__navbar-brand-default {
    display: inline-block;
  }
  .u-header__navbar-brand-mobile {
    display: none;
  }
}

@media (max-width: 575px) {
  .u-header__navbar-brand-default, .u-header__navbar-brand-on-scroll {
    display: none;
  }
  .u-header__navbar-brand-mobile {
    display: inline-block;
  }
}

/*------------------------------------
  Header Navbar Background Colors
------------------------------------*/
.u-header--navbar-bg .u-header__section {
  border-color: transparent;
}

.u-header--navbar-bg.u-header--bordered .u-header__section,
.u-header--navbar-bg.u-header--bordered .u-header__navbar-brand,
.u-header--navbar-bg.u-header--bordered .u-header__nav-item-user,
.u-header--navbar-bg.u-header--bordered .u-header__navbar-v-divider {
  border-color: rgba(255, 255, 255, 0.15);
}

.u-header--navbar-bg .u-header__nav-item:hover .u-header__nav-link {
  color: #fff;
}

.u-header--navbar-bg .u-header__nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-header__nav-link:hover {
  color: #fff;
}

.u-header--navbar-bg [class*="btn-text-"] {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg [class*="btn-text-"]:focus, .u-header--navbar-bg [class*="btn-text-"]:hover {
  color: #fff;
}

.u-header--navbar-bg .u-header__nav-item-user {
  background-color: rgba(255, 255, 255, 0.075);
}

.u-header--navbar-bg .u-header__nav-item-user-name {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-header__nav-item-user-name:hover {
  color: #fff;
}

.u-header--navbar-bg .u-hamburger {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-hamburger:hover {
  color: #fff;
}

.u-header--navbar-bg .u-hamburger__inner, .u-header--navbar-bg .u-hamburger__inner::before, .u-header--navbar-bg .u-hamburger__inner::after {
  background-color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner, .u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::before, .u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::after {
  background-color: #fff;
}

.u-header--navbar-bg .navbar-text {
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Header Submenu Background Colors
------------------------------------*/
.u-header--sub-menu-dark-bg .u-header__navbar-nav .u-header__sub-menu {
  background-color: #151b26;
  border-color: rgba(255, 255, 255, 0.15);
}

.u-header--sub-menu-dark-bg .u-header__navbar-nav .u-header__sub-menu-title {
  color: rgba(255, 255, 255, 0.9);
}

.u-header--sub-menu-dark-bg .u-header__navbar-nav .u-header__sub-menu-nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.u-header--sub-menu-dark-bg .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
  color: #fff;
}

.u-header--sub-menu-dark-bg .u-header__mega-menu-col:not(:last-child) {
  border-right-color: rgba(255, 255, 255, 0.15);
}

.u-header--sub-menu-dark-bg .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.15);
}

@media (min-width: 576px) {
  .u-header--sub-menu-dark-bg-sm .u-header__navbar-nav .u-header__sub-menu {
    background-color: #151b26;
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__navbar-nav .u-header__sub-menu-title {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__navbar-nav .u-header__sub-menu-nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
    color: #fff;
  }
  .u-header--sub-menu-dark-bg-sm .u-header__mega-menu-col:not(:last-child) {
    border-right-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-sm .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
}

@media (min-width: 768px) {
  .u-header--sub-menu-dark-bg-md .u-header__navbar-nav .u-header__sub-menu {
    background-color: #151b26;
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-md .u-header__navbar-nav .u-header__sub-menu-title {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--sub-menu-dark-bg-md .u-header__navbar-nav .u-header__sub-menu-nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-header--sub-menu-dark-bg-md .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
    color: #fff;
  }
  .u-header--sub-menu-dark-bg-md .u-header__mega-menu-col:not(:last-child) {
    border-right-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-md .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
}

@media (min-width: 992px) {
  .u-header--sub-menu-dark-bg-lg .u-header__navbar-nav .u-header__sub-menu {
    background-color: #151b26;
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__navbar-nav .u-header__sub-menu-title {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__navbar-nav .u-header__sub-menu-nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
    color: #fff;
  }
  .u-header--sub-menu-dark-bg-lg .u-header__mega-menu-col:not(:last-child) {
    border-right-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-lg .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
}

@media (min-width: 1200px) {
  .u-header--sub-menu-dark-bg-xl .u-header__navbar-nav .u-header__sub-menu {
    background-color: #151b26;
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__navbar-nav .u-header__sub-menu-title {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__navbar-nav .u-header__sub-menu-nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
    color: #fff;
  }
  .u-header--sub-menu-dark-bg-xl .u-header__mega-menu-col:not(:last-child) {
    border-right-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-xl .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
}

/*------------------------------------
  Header Floating
------------------------------------*/
.u-header--floating:not([class*="u-header--floating-"]) {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 2rem;
}

[class*="u-header--floating"].js-header-fix-moment {
  background-color: #fff;
  margin-top: 0;
}

.u-header--floating.js-header-fix-moment {
  position: fixed;
  border-bottom: 1px solid #e3e6f0;
}

.u-header--floating.js-header-fix-moment .u-header--floating__inner {
  border-radius: 0;
}

.u-header--floating__inner {
  border-bottom: 0;
  border-radius: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Small devices */
@media (min-width: 576px) {
  .u-header--floating-sm {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-sm__inner {
    border-bottom: 0;
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .u-header--floating-sm.js-header-fix-moment {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-sm.js-header-fix-moment .u-header--floating-sm__inner {
    border-radius: 0;
  }
}

@media (max-width: 575px) {
  .u-header--floating-sm {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-sm__inner {
    border-bottom: 0;
  }
}

/* Medium devices */
@media (min-width: 768px) {
  .u-header--floating-md {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-md__inner {
    border-bottom: 0;
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .u-header--floating-md.js-header-fix-moment {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-md.js-header-fix-moment .u-header--floating-md__inner {
    border-radius: 0;
  }
}

@media (max-width: 767px) {
  .u-header--floating-md {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-md__inner {
    border-bottom: 0;
  }
}

/* Large devices */
@media (min-width: 992px) {
  .u-header--floating-lg {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-lg__inner {
    border-bottom: 0;
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .u-header--floating-lg.js-header-fix-moment {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-lg.js-header-fix-moment .u-header--floating-lg__inner {
    border-radius: 0;
  }
}

@media (max-width: 991px) {
  .u-header--floating-lg {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-lg__inner {
    border-bottom: 0;
  }
}

/* Extra Large devices */
@media (min-width: 1200px) {
  .u-header--floating-xl {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-xl__inner {
    border-bottom: 0;
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .u-header--floating-xl.js-header-fix-moment {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-xl.js-header-fix-moment .u-header--floating-xl__inner {
    border-radius: 0;
  }
}

@media (max-width: 1199px) {
  .u-header--floating-xl {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-xl__inner {
    border-bottom: 0;
  }
}

.u-header--floating__inner,
.u-header--floating-sm__inner,
.u-header--floating-md__inner,
.u-header--floating-lg__inner,
.u-header--floating-xl__inner {
  max-height: 90vh;
}

.u-header--floating__inner.mCS_destroyed,
.u-header--floating-sm__inner.mCS_destroyed,
.u-header--floating-md__inner.mCS_destroyed,
.u-header--floating-lg__inner.mCS_destroyed,
.u-header--floating-xl__inner.mCS_destroyed {
  position: static !important;
}

[class*="u-header--floating"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 60px 24px;
}

[class*="u-header--floating"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 70%;
}

[class*="u-header--floating"] .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent !important;
}

/*------------------------------------
  Absolute Positions
------------------------------------*/
.u-header--abs-top, .u-header--abs-bottom, .u-header--abs-top-2nd-screen {
  position: absolute;
}

.u-header--abs-top {
  top: 0;
  bottom: auto;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) {
  top: 100%;
  bottom: auto;
}

.u-header--abs-top-2nd-screen[data-header-fix-effect] {
  transition: none;
}

.u-header--abs-bottom:not(.js-header-fix-moment) {
  top: auto;
  bottom: 0;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom:not(.js-header-fix-moment) .u-header__section {
  border-top: 1px solid #e3e6f0;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-unfold,
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__search,
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) .u-unfold,
.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__search,
.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__sub-menu {
  top: auto;
  bottom: 100%;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
  bottom: 0;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom:not(.js-header-fix-moment) .u-unfold {
  margin-bottom: 0;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom:not(.js-header-fix-moment) .fadeInUp {
  -webkit-animation-name: absoluteFadeInUp;
          animation-name: absoluteFadeInUp;
}

@media (min-width: 576px) {
  .u-header--abs-top-sm, .u-header--abs-bottom-sm, .u-header--abs-top-2nd-screen-sm {
    position: absolute;
  }
  .u-header--abs-top-sm {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-sm[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__section {
    border-top: 1px solid #e3e6f0;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-unfold {
    margin-bottom: 0;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom-sm:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .fadeInUp {
    -webkit-animation-name: absoluteFadeInUp;
            animation-name: absoluteFadeInUp;
  }
}

@media (min-width: 768px) {
  .u-header--abs-top-md, .u-header--abs-bottom-md, .u-header--abs-top-2nd-screen-md {
    position: absolute;
  }
  .u-header--abs-top-md {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-md[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__section {
    border-top: 1px solid #e3e6f0;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-unfold {
    margin-bottom: 0;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom-md:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom-md:not(.js-header-fix-moment) .fadeInUp {
    -webkit-animation-name: absoluteFadeInUp;
            animation-name: absoluteFadeInUp;
  }
}

@media (min-width: 992px) {
  .u-header--abs-top-lg, .u-header--abs-bottom-lg, .u-header--abs-top-2nd-screen-lg {
    position: absolute;
  }
  .u-header--abs-top-lg {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-lg[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__section {
    border-top: 1px solid #e3e6f0;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-unfold {
    margin-bottom: 0;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom-lg:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .fadeInUp {
    -webkit-animation-name: absoluteFadeInUp;
            animation-name: absoluteFadeInUp;
  }
}

@media (min-width: 1200px) {
  .u-header--abs-top-xl, .u-header--abs-bottom-xl, .u-header--abs-top-2nd-screen-xl {
    position: absolute;
  }
  .u-header--abs-top-xl {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-xl[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__section {
    border-top: 1px solid #e3e6f0;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-unfold {
    margin-bottom: 0;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom-xl:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .fadeInUp {
    -webkit-animation-name: absoluteFadeInUp;
            animation-name: absoluteFadeInUp;
  }
}

.u-header--abs__inner {
  max-height: 90vh;
}

.u-header--abs__inner.mCS_destroyed {
  position: static !important;
}

[class*="u-header--abs"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 60px 24px;
}

[class*="u-header--abs"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 70%;
}

[class*="u-header--abs"] .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent !important;
}

/* Disable box-shadow on mobile device */
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none;
}

.u-header--abs-top-2nd-screen-sm .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--abs-top-2nd-screen-md .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--abs-bottom-sm .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--abs-bottom-md .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none !important;
}

@-webkit-keyframes absoluteFadeInUp {
  from {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}

@keyframes absoluteFadeInUp {
  from {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}

/*------------------------------------
  Header Sidebar
------------------------------------*/
.u-header-sidebar__footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 10.625rem;
}

.u-header-sidebar__content {
  padding: 3rem;
}

.u-header-sidebar__navbar-brand {
  display: block;
  width: 6.25rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}

.u-header-sidebar__footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem;
}

.u-header-sidebar__footer-link {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

.u-header-sidebar__footer-link:hover {
  color: #fff;
}

/*------------------------------------
  Sticky Positions
------------------------------------*/
.u-header--sticky-top, .u-header--sticky-bottom {
  position: fixed;
}

.u-header--sticky-top {
  bottom: auto;
  top: 0;
}

.u-header--sticky-bottom {
  top: auto;
  bottom: 0;
}

.u-header--sticky-bottom .u-header__section {
  border-top: 1px solid #e3e6f0;
  border-bottom: 0;
}

.u-header--sticky-bottom .u-unfold,
.u-header--sticky-bottom .u-header__search,
.u-header--sticky-bottom .u-header__sub-menu {
  top: auto;
  bottom: 100%;
}

.u-header--sticky-bottom .u-unfold {
  margin-bottom: 0.4375rem;
}

.u-header--sticky-bottom .u-header__navbar-nav .u-header__sub-menu {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
}

.u-header--sticky-bottom .fadeInUp {
  -webkit-animation-name: stickyFadeInUp;
          animation-name: stickyFadeInUp;
}

@media (min-width: 576px) {
  .u-header--sticky-top-sm, .u-header--sticky-bottom-sm {
    position: fixed;
  }
  .u-header--sticky-top-sm {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-sm {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-sm .u-header__section {
    border-top: 1px solid #e3e6f0;
    border-bottom: 0;
  }
  .u-header--sticky-bottom-sm .u-unfold,
  .u-header--sticky-bottom-sm .u-header__search,
  .u-header--sticky-bottom-sm .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-sm .u-unfold {
    margin-bottom: 0.4375rem;
  }
  .u-header--sticky-bottom-sm .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
  }
  .u-header--sticky-bottom-sm .fadeInUp {
    -webkit-animation-name: stickyFadeInUp;
            animation-name: stickyFadeInUp;
  }
}

@media (min-width: 768px) {
  .u-header--sticky-top-md, .u-header--sticky-bottom-md {
    position: fixed;
  }
  .u-header--sticky-top-md {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-md {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-md .u-header__section {
    border-top: 1px solid #e3e6f0;
    border-bottom: 0;
  }
  .u-header--sticky-bottom-md .u-unfold,
  .u-header--sticky-bottom-md .u-header__search,
  .u-header--sticky-bottom-md .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-md .u-unfold {
    margin-bottom: 0.4375rem;
  }
  .u-header--sticky-bottom-md .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
  }
  .u-header--sticky-bottom-md .fadeInUp {
    -webkit-animation-name: stickyFadeInUp;
            animation-name: stickyFadeInUp;
  }
}

@media (min-width: 992px) {
  .u-header--sticky-top-lg, .u-header--sticky-bottom-lg {
    position: fixed;
  }
  .u-header--sticky-top-lg {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-lg {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-lg .u-header__section {
    border-top: 1px solid #e3e6f0;
    border-bottom: 0;
  }
  .u-header--sticky-bottom-lg .u-unfold,
  .u-header--sticky-bottom-lg .u-header__search,
  .u-header--sticky-bottom-lg .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-lg .u-unfold {
    margin-bottom: 0.4375rem;
  }
  .u-header--sticky-bottom-lg .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
  }
  .u-header--sticky-bottom-lg .fadeInUp {
    -webkit-animation-name: stickyFadeInUp;
            animation-name: stickyFadeInUp;
  }
}

@media (min-width: 1200px) {
  .u-header--sticky-top-xl, .u-header--sticky-bottom-xl {
    position: fixed;
  }
  .u-header--sticky-top-xl {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-xl {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-xl .u-header__section {
    border-top: 1px solid #e3e6f0;
    border-bottom: 0;
  }
  .u-header--sticky-bottom-xl .u-unfold,
  .u-header--sticky-bottom-xl .u-header__search,
  .u-header--sticky-bottom-xl .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-xl .u-unfold {
    margin-bottom: 0.4375rem;
  }
  .u-header--sticky-bottom-xl .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(21, 27, 38, 0.1);
  }
  .u-header--sticky-bottom-xl .fadeInUp {
    -webkit-animation-name: stickyFadeInUp;
            animation-name: stickyFadeInUp;
  }
}

.u-header--sticky__inner {
  max-height: 90vh;
}

.u-header--sticky__inner.mCS_destroyed {
  position: static !important;
}

[class*="u-header--sticky"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 60px 24px;
}

[class*="u-header--sticky"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 70%;
}

[class*="u-header--sticky"] .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent !important;
}

/* Disable box-shadow on mobile device */
.u-header--sticky-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none;
}

.u-header--sticky-top-sm .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--sticky-top-md .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--sticky-bottom-sm .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--sticky-bottom-md .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none !important;
}

@-webkit-keyframes stickyFadeInUp {
  from {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}

@keyframes stickyFadeInUp {
  from {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}

/*------------------------------------
  Header Center Aligned
------------------------------------*/
.u-header--center-aligned__inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-top: 1rem;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  padding-top: 0.5rem;
}

.u-header--center-aligned-sm__inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-top: 1rem;
}

@media (min-width: 576px) {
  .u-header--center-aligned-sm__inner {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    padding-top: 0.5rem;
  }
}

.u-header--center-aligned-md__inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-top: 1rem;
}

@media (min-width: 768px) {
  .u-header--center-aligned-md__inner {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    padding-top: 0.5rem;
  }
}

.u-header--center-aligned-lg__inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-top: 1rem;
}

@media (min-width: 992px) {
  .u-header--center-aligned-lg__inner {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    padding-top: 0.5rem;
  }
}

.u-header--center-aligned-xl__inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-top: 1rem;
}

@media (min-width: 1200px) {
  .u-header--center-aligned-xl__inner {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    padding-top: 0.5rem;
  }
}

@media (max-width: 575px) {
  .u-header--center-aligned .navbar-expand-sm.u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .u-header--center-aligned .navbar-expand-md.u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 991px) {
  .u-header--center-aligned .navbar-expand-lg.u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 1199px) {
  .u-header--center-aligned .navbar-expand-xl.u-header__navbar {
    padding-top: 0;
  }
}

.u-header--center-aligned .navbar-expand.u-header__navbar {
  padding-top: 0;
}

/*------------------------------------
  Hamburgers
------------------------------------*/
.u-hamburger {
  font-size: inherit;
  line-height: 0;
  padding: 0.25rem;
}

.u-hamburger:hover .u-hamburger__inner, .u-hamburger:hover .u-hamburger__inner::before, .u-hamburger:hover .u-hamburger__inner::after {
  background-color: #263F8F;
}

.u-hamburger__box {
  width: 1.375rem;
  height: 0.96875rem;
  display: inline-block;
  position: relative;
}

.u-hamburger__inner {
  display: block;
  top: 0.07813rem;
  margin-top: -0.07813rem;
}

.u-hamburger__inner, .u-hamburger__inner::before, .u-hamburger__inner::after {
  width: 1.375rem;
  height: 0.15625rem;
  background-color: #8c98a4;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.u-hamburger__inner::before, .u-hamburger__inner::after {
  content: "";
  display: block;
}

.u-hamburger__inner::before {
  top: 0.40625rem;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.u-hamburger__inner::after {
  top: 0.8125rem;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner,
.u-hamburger .is-active .u-hamburger__inner {
  -webkit-transform: translate3d(0, 6.5px, 0) rotate(45deg);
          transform: translate3d(0, 6.5px, 0) rotate(45deg);
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::before,
.u-hamburger .is-active .u-hamburger__inner::before {
  -webkit-transform: rotate(-45deg) translate3d(-3.14286px, -4px, 0);
          transform: rotate(-45deg) translate3d(-3.14286px, -4px, 0);
  opacity: 0;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::after,
.u-hamburger .is-active .u-hamburger__inner::after {
  -webkit-transform: translate3d(0, -13px, 0) rotate(-90deg);
          transform: translate3d(0, -13px, 0) rotate(-90deg);
}

.u-hamburger--white .u-hamburger__inner, .u-hamburger--white .u-hamburger__inner::before, .u-hamburger--white .u-hamburger__inner::after {
  background-color: rgba(255, 255, 255, 0.8);
}

.u-hamburger--white:hover .u-hamburger__inner, .u-hamburger--white:hover .u-hamburger__inner::before, .u-hamburger--white:hover .u-hamburger__inner::after {
  background-color: #fff;
}

.js-header-fix-moment .u-hamburger--white .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner::before, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner::after {
  background-color: #8c98a4;
}

.js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::before, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::after {
  background-color: #263F8F;
}

/*------------------------------------
  Unfold (Drop Down)
------------------------------------*/
/* Disable content revealing on page load */
.u-unfold[aria-labelledby] {
  opacity: 0;
}

.u-unfold[aria-labelledby][role="tabpanel"] {
  opacity: 1;
}

.u-unfold[aria-labelledby].u-unfold--css-animation, .u-unfold[aria-labelledby].u-unfold--jquery-slide {
  opacity: 1;
}

.u-unfold.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-unfold {
  position: absolute;
  z-index: 99;
  min-width: 8.4375rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 4px 15px rgba(100, 111, 121, 0.1);
  padding: 1rem 1.5rem;
  border: none;
  margin-bottom: 0;
}

.u-unfold-wrapper {
  color: #646f79;
  font-size: 0.875rem;
}

.u-unfold-wrapper:hover {
  color: #151b26;
}

.u-unfold-wrapper--light {
  color: #8f95a0;
}

.u-unfold-wrapper--light:hover {
  color: #fff;
}

.u-unfold__icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 1.0625rem;
  margin-right: 0.5rem;
}

.u-unfold__icon-pointer {
  font-size: 0.625rem;
  vertical-align: middle;
  margin-left: 0.25rem;
}

/*------------------------------------
  Unfold (Drop Down) - Language
------------------------------------*/
.u-unfold--language {
  min-width: 18.125rem;
  padding: 0;
  margin-bottom: 3.5rem;
}

.u-unfold--language-btn {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.u-unfold--language-btn:hover, .u-unfold--language-btn:focus {
  color: #fff !important;
}

.u-unfold--language__link {
  display: block;
  background-color: #f5f8fb;
}

.active .u-unfold--language-icon-pointer {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

@media (min-width: 576px) {
  .u-unfold--language {
    min-width: 22.5rem;
  }
}

/*------------------------------------
  Unfold (Drop Down) - Cart
------------------------------------*/
.u-unfold--cart {
  margin-top: 1.3125rem;
}

/*------------------------------------
  Alert
------------------------------------*/
.alert-text {
  color: inherit;
}

/*------------------------------------
  Avatar
------------------------------------*/
.u-avatar {
  width: 44px;
  height: 44px;
}

.u-sm-avatar {
  width: 32px;
  height: 32px;
}

.u-md-avatar {
  width: 64px;
  height: 64px;
}

.u-lg-avatar {
  width: 96px;
  height: 96px;
}

/*------------------------------------
  Avatar Bordered
------------------------------------*/
.u-sm-avatar--bordered {
  border: 2px solid #fff;
}

.u-xl-avatar--bordered {
  border: 7px solid #fff;
}

/*------------------------------------
  Badges
------------------------------------*/
[class*="badge-"] {
  z-index: 3;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 1.7;
}

.badge-xs {
  width: 1.125rem;
  height: 1.125rem;
  font-size: 0.625rem;
}

.badge-sm {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 0.875rem;
}

.badge-sm.badge-icon {
  line-height: 1.5;
}

.badge-pos {
  position: absolute;
}

/* Positions */
.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.badge-pos--top-left {
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.badge-pos--bottom-left {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}

.badge-pos--bottom-right {
  bottom: 0;
  right: 0;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
}

.badge-pos--bottom-right-minus-1 {
  bottom: -0.25rem;
  right: -0.25rem;
}

/*------------------------------------
  Buttons
------------------------------------*/
button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.btn {
  font-weight: 400;
  cursor: pointer;
}

.btn-wide {
  min-width: 10rem;
}

/*------------------------------------
  Buttons Bootstrap Style
------------------------------------*/
/* facebook */
.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
}

.btn-facebook:focus, .btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

.btn-facebook.disabled, .btn-facebook:disabled {
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:not([disabled]):not(.disabled):active, .btn-facebook:not([disabled]):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #293e6a;
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

/* google */
.btn-google {
  color: #fff;
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:hover {
  color: #fff;
  background-color: #b33628;
  border-color: #a93325;
}

.btn-google:focus, .btn-google.focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 65, 48, 0.5);
}

.btn-google.disabled, .btn-google:disabled {
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:not([disabled]):not(.disabled):active, .btn-google:not([disabled]):not(.disabled).active,
.show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #a93325;
  border-color: #9e3023;
  box-shadow: 0 0 0 0.2rem rgba(209, 65, 48, 0.5);
}

/* twitter */
.btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-twitter:hover {
  color: #fff;
  background-color: #0d8ddc;
  border-color: #0c85d0;
}

.btn-twitter:focus, .btn-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
}

.btn-twitter.disabled, .btn-twitter:disabled {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-twitter:not([disabled]):not(.disabled):active, .btn-twitter:not([disabled]):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0c85d0;
  border-color: #0b7ec4;
  box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
}

/* instagram */
.btn-instagram {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:hover {
  color: #fff;
  background-color: #345e80;
  border-color: #305777;
}

.btn-instagram:focus, .btn-instagram.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.5);
}

.btn-instagram.disabled, .btn-instagram:disabled {
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:not([disabled]):not(.disabled):active, .btn-instagram:not([disabled]):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #305777;
  border-color: #2d516e;
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.5);
}

/* github */
.btn-github {
  color: #fff;
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:hover {
  color: #fff;
  background-color: #131619;
  border-color: #0e1011;
}

.btn-github:focus, .btn-github.focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 41, 46, 0.5);
}

.btn-github.disabled, .btn-github:disabled {
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:not([disabled]):not(.disabled):active, .btn-github:not([disabled]):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #0e1011;
  border-color: #08090a;
  box-shadow: 0 0 0 0.2rem rgba(36, 41, 46, 0.5);
}

/*------------------------------------
  Button Sizes
------------------------------------*/
/* Extra Small */
.btn-xs {
  font-size: 0.6875rem;
  padding: 0.4375rem 0.9375rem;
}

/* Icons */
.btn-icon {
  position: relative;
  line-height: 0;
  font-size: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
}

.btn-icon__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

/*------------------------------------
  Text Buttons Styles
------------------------------------*/
/* primary text button */
.btn-text-primary {
  color: #263F8F;
  background: transparent;
}

.btn-text-primary:hover, .btn-text-primary:focus, .btn-text-primary:active {
  color: #151b26;
}

/* secondary text button */
.btn-text-secondary {
  color: #646f79;
  background: transparent;
}

.btn-text-secondary:hover, .btn-text-secondary:focus, .btn-text-secondary:active {
  color: #151b26;
}

/* success text button */
.btn-text-success {
  color: #263F8F;
  background: transparent;
}

.btn-text-success:hover, .btn-text-success:focus, .btn-text-success:active {
  color: #151b26;
}

/* info text button */
.btn-text-info {
  color: #00dffc;
  background: transparent;
}

.btn-text-info:hover, .btn-text-info:focus, .btn-text-info:active {
  color: #151b26;
}

/* warning text button */
.btn-text-warning {
  color: #ffbc42;
  background: transparent;
}

.btn-text-warning:hover, .btn-text-warning:focus, .btn-text-warning:active {
  color: #151b26;
}

/* danger text button */
.btn-text-danger {
  color: #da4b48;
  background: transparent;
}

.btn-text-danger:hover, .btn-text-danger:focus, .btn-text-danger:active {
  color: #151b26;
}

/* light text button */
.btn-text-light {
  color: #f5f8fb;
  background: transparent;
}

.btn-text-light:hover, .btn-text-light:focus, .btn-text-light:active {
  color: #d4d7dd;
}

/* dark text button */
.btn-text-dark {
  color: #151b26;
  background: transparent;
}

.btn-text-dark:hover, .btn-text-dark:focus, .btn-text-dark:active {
  color: #151b26;
}

/* purple text button */
.btn-text-purple {
  color: #796eff;
  background: transparent;
}

.btn-text-purple:hover, .btn-text-purple:focus, .btn-text-purple:active {
  color: #151b26;
}

/* blue text button */
.btn-text-blue {
  color: #1c6be1;
  background: transparent;
}

.btn-text-blue:hover, .btn-text-blue:focus, .btn-text-blue:active {
  color: #151b26;
}

/*------------------------------------
  Dropwodn
------------------------------------*/
.dropdown-menu {
  font-size: 0.875rem;
  padding: 1rem 1.5rem;
  box-shadow: 0 4px 15px rgba(100, 111, 121, 0.1);
}

/*------------------------------------
  Divider
------------------------------------*/
.u-divider {
  position: relative;
  display: inline-block;
}

.u-divider::before, .u-divider::after {
  position: absolute;
  top: 50%;
  background-color: #d4d7dd;
  content: "";
}

.u-divider::before {
  right: 100%;
}

.u-divider::after {
  left: 100%;
}

.u-divider-wrapper {
  overflow: hidden;
}

/*------------------------------------
  Divider Sizes
------------------------------------*/
/* Extra Small */
.u-divider--xs {
  font-size: 0.75rem;
}

.u-divider--xs::before, .u-divider--xs::after {
  width: 62.4375rem;
  height: 0.0625rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.u-divider--xs::before {
  margin-right: 0.625rem;
}

.u-divider--xs::after {
  margin-left: 0.625rem;
}

/*------------------------------------
  Divider Styles
------------------------------------*/
/* Text */
.u-divider--text {
  color: #8c98a4;
}

/*------------------------------------
  Icon
------------------------------------*/
.u-icon {
  position: relative;
  z-index: 1;
  line-height: 0.7;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: 0.3s ease-in-out;
}

.u-icon__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-icon--shadow {
  box-shadow: 0 4px 8px 0 #e3e6f0;
}

/*------------------------------------
  Icon Default Styles
------------------------------------*/
/* primary */
.u-icon--primary {
  color: #fff;
  background-color: #263F8F;
  border-color: #263F8F;
}

.u-icon--primary[href]:hover {
  color: #fff;
  background-color: #1ca766;
  border-color: #1a9c5f;
}

.u-icon--primary.disabled, .u-icon--primary:disabled {
  background-color: #263F8F;
  border-color: #263F8F;
}

/* secondary */
.u-icon--secondary {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79;
}

.u-icon--secondary[href]:hover {
  color: #fff;
  background-color: #535c64;
  border-color: #4d555d;
}

.u-icon--secondary.disabled, .u-icon--secondary:disabled {
  background-color: #646f79;
  border-color: #646f79;
}

/* success */
.u-icon--success {
  color: #fff;
  background-color: #263F8F;
  border-color: #263F8F;
}

.u-icon--success[href]:hover {
  color: #fff;
  background-color: #1ca766;
  border-color: #1a9c5f;
}

.u-icon--success.disabled, .u-icon--success:disabled {
  background-color: #263F8F;
  border-color: #263F8F;
}

/* info */
.u-icon--info {
  color: #151b26;
  background-color: #00dffc;
  border-color: #00dffc;
}

.u-icon--info[href]:hover {
  color: #fff;
  background-color: #00bdd6;
  border-color: #00b2c9;
}

.u-icon--info.disabled, .u-icon--info:disabled {
  background-color: #00dffc;
  border-color: #00dffc;
}

/* warning */
.u-icon--warning {
  color: #151b26;
  background-color: #ffbc42;
  border-color: #ffbc42;
}

.u-icon--warning[href]:hover {
  color: #151b26;
  background-color: #ffae1c;
  border-color: #ffaa0f;
}

.u-icon--warning.disabled, .u-icon--warning:disabled {
  background-color: #ffbc42;
  border-color: #ffbc42;
}

/* danger */
.u-icon--danger {
  color: #fff;
  background-color: #da4b48;
  border-color: #da4b48;
}

.u-icon--danger[href]:hover {
  color: #fff;
  background-color: #d12e2a;
  border-color: #c72b28;
}

.u-icon--danger.disabled, .u-icon--danger:disabled {
  background-color: #da4b48;
  border-color: #da4b48;
}

/* light */
.u-icon--light {
  color: #151b26;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.u-icon--light[href]:hover {
  color: #151b26;
  background-color: #dae5f0;
  border-color: #d1dfec;
}

.u-icon--light.disabled, .u-icon--light:disabled {
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

/* dark */
.u-icon--dark {
  color: #fff;
  background-color: #151b26;
  border-color: #151b26;
}

.u-icon--dark[href]:hover {
  color: #fff;
  background-color: #07090d;
  border-color: #030405;
}

.u-icon--dark.disabled, .u-icon--dark:disabled {
  background-color: #151b26;
  border-color: #151b26;
}

/* purple */
.u-icon--purple {
  color: #fff;
  background-color: #796eff;
  border-color: #796eff;
}

.u-icon--purple[href]:hover {
  color: #fff;
  background-color: #5648ff;
  border-color: #4a3bff;
}

.u-icon--purple.disabled, .u-icon--purple:disabled {
  background-color: #796eff;
  border-color: #796eff;
}

/* blue */
.u-icon--blue {
  color: #fff;
  background-color: #1c6be1;
  border-color: #1c6be1;
}

.u-icon--blue[href]:hover {
  color: #fff;
  background-color: #185bbf;
  border-color: #1655b4;
}

.u-icon--blue.disabled, .u-icon--blue:disabled {
  background-color: #1c6be1;
  border-color: #1c6be1;
}

/* facebook */
.u-icon--facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.u-icon--facebook[href]:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
}

.u-icon--facebook.disabled, .u-icon--facebook:disabled {
  background-color: #3b5998;
  border-color: #3b5998;
}

/* google */
.u-icon--google {
  color: #fff;
  background-color: #d14130;
  border-color: #d14130;
}

.u-icon--google[href]:hover {
  color: #fff;
  background-color: #b33628;
  border-color: #a93325;
}

.u-icon--google.disabled, .u-icon--google:disabled {
  background-color: #d14130;
  border-color: #d14130;
}

/* twitter */
.u-icon--twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.u-icon--twitter[href]:hover {
  color: #fff;
  background-color: #0d8ddc;
  border-color: #0c85d0;
}

.u-icon--twitter.disabled, .u-icon--twitter:disabled {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

/* instagram */
.u-icon--instagram {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}

.u-icon--instagram[href]:hover {
  color: #fff;
  background-color: #345e80;
  border-color: #305777;
}

.u-icon--instagram.disabled, .u-icon--instagram:disabled {
  background-color: #3f729b;
  border-color: #3f729b;
}

/* github */
.u-icon--github {
  color: #fff;
  background-color: #24292e;
  border-color: #24292e;
}

.u-icon--github[href]:hover {
  color: #fff;
  background-color: #131619;
  border-color: #0e1011;
}

.u-icon--github.disabled, .u-icon--github:disabled {
  background-color: #24292e;
  border-color: #24292e;
}

/*------------------------------------
  Icon Sizes
------------------------------------*/
/* Extra Small */
.u-icon--xs {
  width: 1.375rem;
  height: 1.375rem;
  font-size: 0.5625rem;
  border-radius: 0.25rem;
}

/* Small */
.u-icon--sm {
  width: 2rem;
  height: 2rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
}

/* Medium */
.u-icon--md {
  width: 4rem;
  height: 4rem;
  font-size: 1.75rem;
  border-radius: 0.25rem;
}

/* Large */
.u-icon--lg {
  width: 6rem;
  height: 6rem;
  font-size: 2.25rem;
  border-radius: 0.25rem;
}

/*------------------------------------
  Form
------------------------------------*/
.form {
  border-radius: 0.25rem;
}

.form__input {
  z-index: 2;
}

.form__append, .form__prepend,
.form__prepend > .btn,
.form__append > .btn, .form__text {
  background-clip: padding-box;
  transition: all 0.2s ease-in-out;
}

.form__append .btn {
  margin-left: 1px;
}

.form__text {
  position: relative;
}

.form__text-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.form--no-brd .form__input,
.form--no-brd .form__text {
  border-color: transparent;
}

.form--white-brd .form__input,
.form--white-brd .form__text {
  border-color: #fff;
}

.form--no-addon-brd .form__input:not(:first-child) {
  border-left: none;
}

.form--no-addon-brd .form__input:not(:last-child) {
  border-right: none;
}

/*------------------------------------
  Form Sizes
------------------------------------*/
.form .form__text {
  min-width: 3rem;
  font-size: 0.8125rem;
}

.form--sm .form__text {
  min-width: 2.6875rem;
}

.form--lg .form__text {
  font-size: 1.125rem;
  min-width: 3.875rem;
}

.form--lg .form__append > .btn,
.form--lg .form__prepend > .btn {
  font-size: 1.125rem;
}

/*------------------------------------
  Form Selector
------------------------------------*/
.form-selector {
  padding-left: 0;
  margin-right: 0.5rem;
  margin-bottom: 0.75rem;
}

.form-selector__input:checked ~ .form-selector__label {
  border-color: #263F8F;
}

.form-selector__label {
  text-align: center;
  cursor: pointer;
  min-width: 5rem;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.form-selector__label::before, .form-selector__label::after {
  display: none;
}

/*------------------------------------
  Form Selector Modern
------------------------------------*/
.form-selector-modern {
  width: 100%;
  padding-left: 0;
  margin-right: 0;
  margin-bottom: 1rem;
}

.form-selector-modern__input:checked ~ .form-selector-modern__label {
  border-color: #263F8F;
}

.form-selector-modern__label {
  width: 100%;
  cursor: pointer;
  border: 2px solid #e3e6f0;
  border-radius: 0.25rem;
  padding: 1.25rem;
  padding-left: 3rem;
}

.form-selector-modern__label::before, .form-selector-modern__label::after {
  top: 1.5rem;
  left: 1.25rem;
}

/*------------------------------------
  Success State
------------------------------------*/
.u-has-success .form.u-focus-state {
  box-shadow: 0 0 25px rgba(33, 200, 122, 0.1);
}

.u-has-success .form:focus,
.u-has-success .form.u-focus-state .form__text,
.u-has-success .form.u-focus-state .form__input,
.u-has-success .form.u-focus-state *:focus {
  border-color: #263F8F;
}

.u-has-success .form.u-focus-state .form__text {
  color: #263F8F;
}

/*------------------------------------
  Error State
------------------------------------*/
.u-has-error .form.u-focus-state {
  box-shadow: 0 0 25px rgba(218, 75, 72, 0.1);
}

.u-has-error .form:focus,
.u-has-error .form.u-focus-state .form__text,
.u-has-error .form.u-focus-state .form__input,
.u-has-error .form.u-focus-state *:focus {
  border-color: #da4b48;
}

.u-has-error .form.u-focus-state .form__text {
  color: #da4b48;
}

/*------------------------------------
  Focus States
------------------------------------*/
.form {
  transition: all 0.2s ease-in-out;
}

.form.u-focus-state {
  box-shadow: 0 0 25px rgba(100, 111, 121, 0.1);
}

.form:focus,
.form.u-focus-state .form__text,
.form.u-focus-state .form__input,
.form.u-focus-state *:focus {
  border-color: rgba(21, 27, 38, 0.5);
}

.form.u-focus-state .form__text {
  color: #151b26;
}

/* White Border */
.form--white-brd.u-focus-state .form__input {
  border-color: #fff;
}

/*------------------------------------
  Sidebar Navigation
------------------------------------*/
/* Disable content revealing on page load */
.u-sidebar[aria-labelledby] {
  opacity: 0;
}

.u-sidebar[aria-labelledby][role="tabpanel"] {
  opacity: 1;
}

.u-sidebar[aria-labelledby].u-unfold--css-animation, .u-sidebar[aria-labelledby].u-unfold--jquery-slide {
  opacity: 1;
}

.u-sidebar.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 102;
  width: 18.75rem;
  height: 100%;
  background-color: #fff;
  box-shadow: -8px 0 18px rgba(21, 27, 38, 0.07);
}

.u-sidebar__body, .u-sidebar__content {
  height: 100%;
}

.u-sidebar--left {
  right: auto;
  left: 0;
  box-shadow: 8px 0 18px rgba(21, 27, 38, 0.07);
}

.u-sidebar__close {
  color: #646f79;
}

.u-sidebar__close:hover {
  color: #151b26;
  opacity: 1;
}

.u-sidebar__scroller {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.u-sidebar__container {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.u-sidebar__footer {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

/*------------------------------------
  Sidebar Panel
------------------------------------*/
/* Link */
.u-sidebar--panel__link {
  color: #646f79;
}

.u-sidebar--panel__link:hover {
  color: #263F8F;
}

/* Footer */
.u-sidebar--panel__footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 9.5rem;
}

.u-sidebar__footer--panel {
  background: #fff;
  border-top: 1px solid #e3e6f0;
}

/*------------------------------------
  Card
------------------------------------*/
.card-2-count {
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
}

.card-3-count {
  -webkit-column-count: 3;
     -moz-column-count: 3;
          column-count: 3;
}

.card-4-count {
  -webkit-column-count: 4;
     -moz-column-count: 4;
          column-count: 4;
}

.card-columns {
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  orphans: 1;
  widows: 1;
}

.card-columns .card {
  display: inline-block;
  width: 100%;
}

@media (min-width: 576px) {
  .card-sm-2-count {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .card-sm-3-count {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .card-sm-4-count {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .card-sm-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-sm-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .card-md-2-count {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .card-md-3-count {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .card-md-4-count {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .card-md-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-md-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .card-lg-2-count {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .card-lg-3-count {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .card-lg-4-count {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .card-lg-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-lg-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .card-xl-2-count {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .card-xl-3-count {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .card-xl-4-count {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .card-xl-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-xl-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.card-collapse {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  background-color: transparent;
}

.card-collapse__header {
  padding: 0;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.card-collapse__btn {
  color: #151b26;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  white-space: inherit;
}

.card-collapse__btn-arrow {
  color: #263F8F;
  margin-left: 1rem;
  transition: 0.3s ease-in-out;
}

.card-collapse__btn-arrow-inner {
  font-size: 0.8125rem;
}

.collapsed .card-collapse__btn-arrow {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.card-collapse__body {
  color: #646f79;
}

.card-img-right {
  width: 100%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card-img-left {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* Card Frame */
.card-frame {
  border: 1px solid #e3e6f0;
  border-top-width: 4px;
  transition: 0.3s;
}

.card-frame.active, .card-frame:hover {
  border-color: rgba(33, 200, 122, 0.7);
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.25);
}

.card-primary-frame.active, .card-primary-frame:hover {
  border-color: #263F8F;
}

.card-secondary-frame.active, .card-secondary-frame:hover {
  border-color: #646f79;
}

.card-success-frame.active, .card-success-frame:hover {
  border-color: #263F8F;
}

.card-info-frame.active, .card-info-frame:hover {
  border-color: #00dffc;
}

.card-warning-frame.active, .card-warning-frame:hover {
  border-color: #ffbc42;
}

.card-danger-frame.active, .card-danger-frame:hover {
  border-color: #da4b48;
}

.card-light-frame.active, .card-light-frame:hover {
  border-color: #f5f8fb;
}

.card-dark-frame.active, .card-dark-frame:hover {
  border-color: #151b26;
}

.card-purple-frame.active, .card-purple-frame:hover {
  border-color: #796eff;
}

.card-blue-frame.active, .card-blue-frame:hover {
  border-color: #1c6be1;
}

@media (min-width: 576px) {
  /* Gutters X */
  .card-sm-gutters-1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .card-sm-gutters-1 .card {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .card-sm-gutters-2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .card-sm-gutters-2 .card {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .card-sm-gutters-3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-sm-gutters-3 .card {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media (min-width: 768px) {
  /* Gutters X */
  .card-md-gutters-1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .card-md-gutters-1 .card {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .card-md-gutters-2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .card-md-gutters-2 .card {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .card-md-gutters-3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-md-gutters-3 .card {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media (min-width: 992px) {
  /* Gutters X */
  .card-lg-gutters-1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .card-lg-gutters-1 .card {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .card-lg-gutters-2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .card-lg-gutters-2 .card {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .card-lg-gutters-3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-lg-gutters-3 .card {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media (min-width: 1200px) {
  /* Gutters X */
  .card-xl-gutters-1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .card-xl-gutters-1 .card {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .card-xl-gutters-2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .card-xl-gutters-2 .card {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .card-xl-gutters-3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-xl-gutters-3 .card {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

/*------------------------------------
  Clients
------------------------------------*/
.u-clients {
  width: 5rem;
  margin-left: auto;
  margin-right: auto;
}

/*------------------------------------
  Go To
------------------------------------*/
.u-go-to {
  display: none;
  position: relative;
  z-index: 100;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #263F8F;
  color: #fff;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  opacity: 0.5;
  transition: 0.3s ease-out;
}

.u-go-to__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-go-to:hover, .u-go-to:focus:hover {
  color: #fff;
  opacity: 1;
}

/*------------------------------------
  Label
------------------------------------*/
.u-label {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.625rem 1.125rem;
  border-radius: 6.1875rem;
  transition: 0.2s ease-in-out;
}

.u-label--rounded {
  border-radius: 6.1875rem;
}

/*------------------------------------
  Label Sizes
------------------------------------*/
/* Small */
.u-label--sm {
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
}

/*------------------------------------
  Label Colors
------------------------------------*/
/* label primary */
.u-label--primary {
  color: #263F8F;
  background-color: rgba(33, 200, 122, 0.1);
}

.u-label--primary[href]:hover, .u-label--primary[href]:focus {
  color: #fff;
  background-color: #263F8F;
}

/* label secondary */
.u-label--secondary {
  color: #646f79;
  background-color: rgba(100, 111, 121, 0.1);
}

.u-label--secondary[href]:hover, .u-label--secondary[href]:focus {
  color: #fff;
  background-color: #646f79;
}

/* label success */
.u-label--success {
  color: #263F8F;
  background-color: rgba(33, 200, 122, 0.1);
}

.u-label--success[href]:hover, .u-label--success[href]:focus {
  color: #fff;
  background-color: #263F8F;
}

/* label info */
.u-label--info {
  color: #00dffc;
  background-color: rgba(0, 223, 252, 0.1);
}

.u-label--info[href]:hover, .u-label--info[href]:focus {
  color: #151b26;
  background-color: #00dffc;
}

/* label warning */
.u-label--warning {
  color: #ffbc42;
  background-color: rgba(255, 188, 66, 0.1);
}

.u-label--warning[href]:hover, .u-label--warning[href]:focus {
  color: #151b26;
  background-color: #ffbc42;
}

/* label danger */
.u-label--danger {
  color: #da4b48;
  background-color: rgba(218, 75, 72, 0.1);
}

.u-label--danger[href]:hover, .u-label--danger[href]:focus {
  color: #fff;
  background-color: #da4b48;
}

/* label light */
.u-label--light {
  color: #f5f8fb;
  background-color: rgba(245, 248, 251, 0.1);
}

.u-label--light[href]:hover, .u-label--light[href]:focus {
  color: #151b26;
  background-color: #f5f8fb;
}

/* label dark */
.u-label--dark {
  color: #151b26;
  background-color: rgba(21, 27, 38, 0.1);
}

.u-label--dark[href]:hover, .u-label--dark[href]:focus {
  color: #fff;
  background-color: #151b26;
}

/* label purple */
.u-label--purple {
  color: #796eff;
  background-color: rgba(121, 110, 255, 0.1);
}

.u-label--purple[href]:hover, .u-label--purple[href]:focus {
  color: #fff;
  background-color: #796eff;
}

/* label blue */
.u-label--blue {
  color: #1c6be1;
  background-color: rgba(28, 107, 225, 0.1);
}

.u-label--blue[href]:hover, .u-label--blue[href]:focus {
  color: #fff;
  background-color: #1c6be1;
}

/*------------------------------------
  Modal Window
------------------------------------*/
.u-modal-window {
  position: relative;
  display: none;
  max-height: 88vh;
  background-color: #fff;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.u-modal-window__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

/*------------------------------------
  Media Player
------------------------------------*/
.u-media-player {
  color: #151b26;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

.u-media-player__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 3.75rem;
  height: 3.75rem;
  font-size: 0.8125rem;
}

.u-media-player__icon::before {
  display: inline-block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}

.u-media-player__icon-inner {
  color: #263F8F;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: 0.125rem;
}

.u-media-player__icon--box-shadow::before {
  box-shadow: 0 0 40px #e3e6f0;
}

.u-media-player:hover .u-media-player__icon::before {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* Extra Large Size */
.u-media-player__icon--xl {
  width: 6.25rem;
  height: 6.25rem;
  font-size: 1rem;
}

/*------------------------------------
  Media Viewer
------------------------------------*/
.u-media-viewer {
  position: relative;
  display: block;
  overflow: hidden;
}

.u-media-viewer:hover .u-media-viewer__img {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.u-media-viewer:hover .u-media-viewer__icon {
  opacity: 1;
}

.u-media-viewer__img {
  transition: 0.4s;
}

.u-media-viewer__container {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1rem;
}

.u-media-viewer__icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  background-color: #263F8F;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1rem;
  border-radius: 50%;
  opacity: 0;
  transition: 0.4s;
}

.u-media-viewer__icon:hover, .u-media-viewer__icon:focus {
  color: #fff;
}

.u-media-viewer__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*------------------------------------
  Video Player
------------------------------------*/
.u-video-player {
  position: relative;
  background-color: #000;
}

.u-video-player__preview {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 1;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.u-video-player__played .u-video-player__preview {
  opacity: 0;
  pointer-events: none;
}

.u-video-player__btn {
  z-index: 3;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.u-video-player__played .u-video-player__btn {
  -webkit-animation: videoPlayerButton 0.4s ease-in-out forwards;
          animation: videoPlayerButton 0.4s ease-in-out forwards;
  pointer-events: none;
}

.u-video-player__btn:hover .u-video-player__icon::before, .u-video-player__btn:focus .u-video-player__icon::before {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.u-video-player__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 6rem;
  height: 6rem;
  font-size: 0.8125rem;
}

.u-video-player__icon::before {
  display: inline-block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}

.u-video-player__icon-inner {
  color: #263F8F;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: 0.125rem;
}

@media (max-width: 767px) {
  .u-video-player__icon {
    width: 4rem;
    height: 4rem;
  }
}

/* Positions */
.u-video-player__centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@-webkit-keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
            transform: translate(-50%, -50%) scale(1.3);
  }
}

@keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
            transform: translate(-50%, -50%) scale(1.3);
  }
}

/*------------------------------------
  Pagination
------------------------------------*/
.page-link {
  text-align: center;
  min-width: 2.25rem;
}

.page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.page-item .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*------------------------------------
  Vertical Progress Bar
------------------------------------*/
.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  -ms-flex-pack: end;
      justify-content: flex-end;
  height: 12.5rem;
}

.progress-vertical__inner {
  border-radius: 6.1875rem;
}

/*------------------------------------
  Quantity
------------------------------------*/
.u-quantity {
  width: 4rem;
}

.u-quantity > .u-quantity__input {
  padding-right: 30px;
}

.u-quantity > .u-quantity__input:not(:last-child) {
  border-radius: 0.25rem;
}

.u-quantity__arrows {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 0.75rem;
  z-index: 3;
}

.u-quantity__arrows-inner {
  display: block;
  color: #646f79;
  cursor: pointer;
}

.u-quantity__arrows-inner:hover {
  color: #263F8F;
}

/*------------------------------------
  Shopping Cart
------------------------------------*/
/* Item */
.u-shopping-cart {
  border: 1px solid #e3e6f0;
  margin-top: 1.3125rem;
  border-radius: 0;
}

.u-shopping-cart__item-divider {
  border-bottom: 1px solid #e3e6f0;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.u-shopping-cart__item-img-wrapper {
  width: 25%;
}

.u-shopping-cart__item-info-wrapper {
  width: 75%;
}

.u-shopping-cart__item-remover {
  cursor: pointer;
  color: #bfc3ca;
}

.u-shopping-cart__item-remover:hover {
  color: #151b26;
}

/*------------------------------------
  Shopping Cart - Color Selector
------------------------------------*/
.u-shopping-cart-color-selector {
  display: inline-block;
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.125rem;
  border: 3px solid #fff;
  border-radius: 50%;
}

.u-shopping-cart-color-selector.active {
  box-shadow: 0 0 0 2px #bfc3ca;
}

.u-shopping-cart-color-selector.active:hover {
  box-shadow: 0 0 0 2px #bfc3ca;
}

.u-shopping-cart-color-selector:hover {
  box-shadow: 0 0 0 2px #e3e6f0;
}

/*------------------------------------
  Shopping Cart - Step Form
------------------------------------*/
.u-shopping-cart-step-form {
  margin-left: 0;
  text-align: center;
}

.u-shopping-cart-step-form__item {
  width: 100%;
  border-bottom: 2px solid #e3e6f0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.u-shopping-cart-step-form__item:not(:last-child) {
  margin-right: 0;
}

@media (min-width: 576px) {
  .u-shopping-cart-step-form__item {
    width: 33.33333%;
  }
}

.u-shopping-cart-step-form__item.active {
  border-bottom-color: #263F8F;
}

.u-shopping-cart-step-form__item.active .u-shopping-cart-step-form__title,
.u-shopping-cart-step-form__item.active .u-shopping-cart-step-form__subtitle {
  color: #263F8F;
}

.u-shopping-cart-step-form__title {
  display: block;
  font-size: 1.25rem;
  color: #151b26;
}

.u-shopping-cart-step-form__subtitle {
  display: block;
  font-size: 0.875rem;
  color: #8c98a4;
}

/* Toggler */
.u-shopping-cart-step-form__toggler {
  display: block;
  width: 100%;
  text-align: center;
  color: #646f79;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
  padding: 1.5rem;
  min-width: 13.75rem;
  margin-right: 1rem;
  transition: 0.3s;
}

.u-shopping-cart-step-form__toggler.active, .u-shopping-cart-step-form__toggler:hover {
  color: #263F8F;
  border-color: #263F8F;
}

.u-shopping-cart-step-form__toggler-icon {
  font-size: 1.5rem;
}

.u-shopping-cart-step-form__toggler-img {
  width: 5rem;
  height: auto;
}

/*------------------------------------
  Slick
------------------------------------*/
.u-slick {
  position: relative;
}

.u-slick--cursor-pointer .slick-slide {
  cursor: pointer;
}

.u-slick--transform-off.slick-transform-off .slick-track,
.u-slick--transform-off .slick-track {
  -webkit-transform: none !important;
          transform: none !important;
}

@media (min-width: 992px) {
  .u-slick--transform-off-lg.slick-transform-off .slick-track {
    -webkit-transform: none !important;
            transform: none !important;
  }
}

/*------------------------------------
  Slick Equal Height
------------------------------------*/
.u-slick--equal-height .slick-list {
  height: 100%;
}

.u-slick--equal-height .slick-track {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.u-slick--equal-height .slick-track .slick-slide {
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

/*------------------------------------
  Slick Gutters
------------------------------------*/
.u-slick--gutters {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.u-slick--gutters .slick-slide {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.u-slick--gutters-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.u-slick--gutters-2 .slick-slide {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.u-slick--gutters-3 {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}

.u-slick--gutters-3 .slick-slide {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}

/*------------------------------------
  Slick Arrows
------------------------------------*/
.u-slick__arrow {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #646f79;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.125rem;
  cursor: pointer;
  transition: 0.3s;
}

.u-slick__arrow:hover {
  color: #263F8F;
}

.u-slick__arrow-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-slick__arrow-inner--left {
  left: 0;
}

.u-slick__arrow-inner--right {
  right: 0;
}

.u-slick__arrow.slick-disabled {
  color: #e3e6f0;
  cursor: auto;
}

.u-slick__arrow-pos--bottom-minus-7 {
  position: absolute;
  bottom: -4rem;
}

/* Center Alignment */
.u-slick__arrow-centered--x {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.u-slick__arrow-centered--y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

/*------------------------------------
  Slick Arrows Classic
------------------------------------*/
.u-slick__arrow-classic {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  width: 4rem;
  height: 4rem;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
}

.u-slick__arrow-classic:hover {
  color: #fff;
  background-color: #263F8F;
}

.u-slick__arrow-classic-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-slick__arrow-classic-inner--left {
  left: 0;
}

.u-slick__arrow-classic-inner--right {
  right: 0;
}

/* Dark Version */
.u-slick__arrow-classic--dark {
  color: #646f79;
  background-color: rgba(100, 111, 121, 0.1);
}

/*------------------------------------
  Slick Paging v1
------------------------------------*/
.u-slick__paging-v1 {
  position: absolute;
  right: 0;
  bottom: 2rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.u-slick__paging-v1 .u-paging__current {
  color: #fff;
  font-size: 3.875rem;
  line-height: 1;
}

.u-slick__paging-v1 .u-paging__divider {
  position: relative;
  margin-right: 0.5rem;
  margin-left: 0.25rem;
}

.u-slick__paging-v1 .u-paging__divider::before {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  content: "\002f";
}

.u-slick__paging-v1 .u-paging__total {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Slick Pagination
------------------------------------*/
.u-slick__pagination {
  padding-left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  pointer-events: none;
}

.u-slick__pagination li {
  display: -ms-flexbox;
  display: flex;
  pointer-events: all;
  margin: 0 0.5rem;
  cursor: pointer;
}

.u-slick__pagination li span {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
  background-color: #8c98a4;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  transition: 0.3s;
}

.u-slick__pagination li.slick-active span {
  background-color: transparent;
  border-color: #8c98a4;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

/* White Option */
.u-slick__pagination.u-slick__pagination--white li span {
  background-color: #fff;
}

.u-slick__pagination.u-slick__pagination--white li.slick-active span {
  background-color: transparent;
  border-color: #fff;
}

/*------------------------------------
  Slick Pagination - Active Border
------------------------------------*/
.u-slick--pagination-active-border .u-slick--pagination-active-border__item {
  cursor: pointer;
  border: 1px solid #e3e6f0;
}

.u-slick--pagination-active-border .slick-current .u-slick--pagination-active-border__item {
  border-color: #263F8F;
}

/*------------------------------------
  Slick Pagination - Active Transition
------------------------------------*/
.u-slick--active-transition .slick-slide {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
  transition: 0.3s;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.u-slick--active-transition .slick-current {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*------------------------------------
  Slick Pagination Bordered
------------------------------------*/
.u-slick__pagination-bordered {
  padding-left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  pointer-events: none;
}

.u-slick__pagination-bordered li {
  display: -ms-flexbox;
  display: flex;
  pointer-events: all;
  margin: 0 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.u-slick__pagination-bordered li span {
  display: inline-block;
  width: 4rem;
  height: 0.25rem;
  box-sizing: border-box;
  background-color: #8c98a4;
  transition: 0.3s;
}

.u-slick__pagination-bordered li:hover span {
  background-color: rgba(33, 200, 122, 0.4);
}

.u-slick__pagination-bordered li.slick-active span {
  background-color: #263F8F;
}

/* White Option */
.u-slick__pagination-bordered.u-slick__pagination-bordered--white li span {
  background-color: rgba(255, 255, 255, 0.5);
}

.u-slick__pagination-bordered.u-slick__pagination-bordered--white li:hover span {
  background-color: rgba(255, 255, 255, 0.7);
}

.u-slick__pagination-bordered.u-slick__pagination-bordered--white li.slick-active span {
  background-color: #fff;
}

/*------------------------------------
  Slick Pagination Classic
------------------------------------*/
.u-slick--pagination-classic .slick-slide {
  cursor: pointer;
  opacity: .5;
  border-top: 4px solid rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

.u-slick--pagination-classic .slick-current {
  opacity: 1;
  border-top-color: #fff;
}

/*------------------------------------
  Slick Pagination Modern
------------------------------------*/
.u-slick--pagination-modern {
  margin-right: -15px;
}

.u-slick--pagination-modern__item {
  padding-right: 15px;
}

@media (min-width: 992px) {
  .u-slick--pagination-modern__item {
    max-width: 25%;
  }
}

.u-slick--pagination-modern__item-img {
  width: 6.25rem;
  height: 4.375rem;
}

.u-slick--pagination-modern__item-text {
  transition: 0.3s;
}

.u-slick--pagination-modern__item.slick-current {
  color: #263F8F;
}

.u-slick--pagination-modern__item:hover .u-slick--pagination-modern__item-text {
  color: #263F8F;
}

/*------------------------------------
  Slick Pagination - Simple
------------------------------------*/
.u-slick--pagination-simple .u-slick--pagination-simple__item {
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.u-slick--pagination-simple .u-slick--pagination-simple__icon {
  position: relative;
  z-index: 1;
  line-height: 0.7;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 2.75rem;
  height: 2.75rem;
  color: #646f79;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.25rem;
  border: 1px solid #e3e6f0;
  transition: 0.3s ease-in-out;
}

.u-slick--pagination-simple .u-slick--pagination-simple__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-slick--pagination-simple .slick-current .u-slick--pagination-simple__title,
.u-slick--pagination-simple .slick-current .u-slick--pagination-simple__text {
  color: #263F8F;
}

.u-slick--pagination-simple .slick-current .u-slick--pagination-simple__icon {
  color: #263F8F;
  border-color: #263F8F;
}

/*------------------------------------
  Tab Vertical
------------------------------------*/
.tab-vertical .tab-vertical__nav-link {
  color: #646f79;
  font-weight: 400;
  border-radius: 0;
  padding-left: 0;
}

.tab-vertical .tab-vertical__nav-link.active {
  color: #263F8F;
  background-color: transparent;
}

.tab-vertical .tab-vertical__nav-link:hover {
  color: #3B65EF;
}

@media (min-width: 768px) {
  .tab-vertical-md {
    position: relative;
    box-shadow: 26px 0 26px -12px rgba(100, 111, 121, 0.06);
  }
  .tab-vertical-md::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background-color: rgba(100, 111, 121, 0.09);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    content: " ";
  }
  .tab-vertical-md .tab-vertical__nav-link {
    position: relative;
  }
  .tab-vertical-md .tab-vertical__nav-link::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background-color: transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    content: " ";
  }
  .tab-vertical-md .tab-vertical__nav-link.active::after {
    background-color: #263F8F;
  }
  .tab-vertical-md .tab-vertical__nav-link:hover {
    color: #263F8F;
  }
}

/*------------------------------------
  Tab Modern
------------------------------------*/
.tab-modern {
  border-bottom: none;
}

.tab-modern .tab-modern__nav-link {
  min-width: 15rem;
  color: #646f79;
  text-align: center;
  font-weight: 400;
  border: none;
  border-bottom: 2px solid #e3e6f0;
  border-radius: 0;
  padding: 0.5rem;
}

.tab-modern .tab-modern__nav-link-icon {
  display: block;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
}

.tab-modern .tab-modern__nav-link.active {
  color: #263F8F;
  border-bottom-color: #263F8F;
  background-color: transparent;
}

.tab-modern .tab-modern__nav-link.active .tab-modern__nav-link-icon {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

.tab-modern .tab-modern__nav-link:hover {
  color: #263F8F;
}

.tab-modern .tab-modern__nav-link:hover .tab-modern__nav-link-icon {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

/*------------------------------------
  Timeline
------------------------------------*/
.u-timeline {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.u-timeline__item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

@media (min-width: 992px) {
  .u-timeline__item {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .u-timeline__item:nth-child(odd) .u-timeline__content {
    -ms-flex-order: 2;
        order: 2;
    padding-left: 3rem;
  }
  .u-timeline__item:nth-child(odd) .u-timeline__info {
    -ms-flex-order: 1;
        order: 1;
  }
  .u-timeline__item:nth-child(odd) .u-timeline__spacer {
    -ms-flex-order: 3;
        order: 3;
  }
}

@media (min-width: 992px) {
  .u-timeline__item:nth-child(even) .u-timeline__content {
    -ms-flex-order: 2;
        order: 2;
    padding-left: 3rem;
  }
  .u-timeline__item:nth-child(even) .u-timeline__info {
    -ms-flex-order: 3;
        order: 3;
    -ms-flex-pack: end;
        justify-content: flex-end;
  }
  .u-timeline__item:nth-child(even) .u-timeline__spacer {
    -ms-flex-order: 1;
        order: 1;
  }
}

.u-timeline__icon {
  position: absolute;
  top: 0;
  left: 0.9375rem;
}

@media (min-width: 992px) {
  .u-timeline__icon {
    left: 0;
  }
}

.u-timeline__content, .u-timeline__info {
  width: calc(100% - 64px);
}

.u-timeline__info, .u-timeline__spacer {
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 992px) {
  .u-timeline__info, .u-timeline__spacer {
    width: 33%;
  }
}

.u-timeline__info, .u-timeline__content, .u-timeline__spacer {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

@media (max-width: 991px) {
  .u-timeline__info, .u-timeline__content, .u-timeline__spacer {
    padding-left: 4rem;
  }
}

.u-timeline__info, .u-timeline__content {
  position: relative;
}

.u-timeline__info::before, .u-timeline__content::before {
  position: absolute;
  top: 0.5rem;
  bottom: 0;
  left: 1.875rem;
  height: 100%;
  border-left: 3px dashed #e3e6f0;
  content: "";
}

@media (min-width: 992px) {
  .u-timeline__info::before, .u-timeline__content::before {
    left: 15px;
  }
}

.u-timeline__content {
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .u-timeline__content {
    width: 50%;
    padding-bottom: 4rem;
  }
}

.u-timeline__info {
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .u-timeline__info {
    padding-bottom: 0;
  }
  .u-timeline__info::before {
    display: none;
  }
}

/*------------------------------------
  Background Colors
------------------------------------*/
.bg-gray-100 {
  background-color: #f5f8fb;
}

.bg-purple-opacity-0_07 {
  background-color: rgba(121, 110, 255, 0.07);
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-img-hero-bottom {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.bg-img-hero-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/*------------------------------------
  Borders
------------------------------------*/
.rounded-pill {
  border-radius: 6.1875rem !important;
}

/*------------------------------------
  Content Centered
------------------------------------*/
.content-centered-y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.content-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*------------------------------------
  Background Gradients
------------------------------------*/
.gradient-half-primary-v1 {
  background-image: linear-gradient(25deg, #796eff 0%, #263F8F 100%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/
[class*="gradient-overlay-half"] {
  position: relative;
  z-index: 1;
}

[class*="gradient-overlay-half"]::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
}

.gradient-overlay-half-dark-video-v1::before {
  z-index: 2;
  background-color: rgba(21, 27, 38, 0.95);
}

.gradient-overlay-half-white-v1::before {
  background-image: linear-gradient(60deg, rgba(255, 255, 255, 0.99) 30%, transparent 60%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v1::before {
  background-image: linear-gradient(30deg, rgba(21, 27, 38, 0.5) 0%, transparent 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v2::before {
  background-image: linear-gradient(0deg, rgba(21, 27, 38, 0.6) 0%, transparent 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v3::before {
  background-image: linear-gradient(0deg, transparent 0%, rgba(21, 27, 38, 0.5) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v4::before {
  background-image: linear-gradient(150deg, rgba(21, 27, 38, 0.9) 10%, #151b26 50%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-primary-v1::before {
  background-image: linear-gradient(30deg, rgba(121, 110, 255, 0.95) 0%, rgba(33, 200, 122, 0.95) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-purple-v1::before {
  background-image: linear-gradient(180deg, rgba(121, 110, 255, 0.35) 10%, #796eff 70%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-purple-v2::before {
  background-image: linear-gradient(150deg, rgba(121, 110, 255, 0.9) 10%, #796eff 50%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Margin Spacing
------------------------------------*/
.ml-offset-3 {
  margin-left: -1rem;
}

.ml-offset-5 {
  margin-left: -2rem;
}

.mt-offset-1 {
  margin-top: -0.25rem;
}

.mt-offset-9 {
  margin-top: -4rem;
}

.mb-offset-9 {
  margin-bottom: -4rem;
}

.mb-offset-14 {
  margin-bottom: -7rem;
}

/* Gutters X */
.mx-gutters-1 {
  margin-right: -4px;
  margin-left: -4px;
}

.mx-gutters-1 > .col,
.mx-gutters-1 > [class*="col-"] {
  padding-right: 4px;
  padding-left: 4px;
}

.mx-gutters-2 {
  margin-right: -8px;
  margin-left: -8px;
}

.mx-gutters-2 > .col,
.mx-gutters-2 > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}

/*------------------------------------
  Position Spaces
------------------------------------*/
.position-absolute-bottom-0 {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.position-absolute-bottom-left-0 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

@media (min-width: 576px) {
  .position-absolute-bottom-0--sm {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 768px) {
  .position-absolute-top-left-0--md {
    position: absolute;
    top: 0;
    left: 0;
  }
  .position-absolute-top-right-0--md {
    position: absolute;
    top: 0;
    right: 0;
  }
  .position-absolute-bottom-left-0--md {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 992px) {
  .position-absolute-top-left-0--lg {
    position: absolute;
    top: 0;
    left: 0;
  }
  .position-absolute-top-right-0--lg {
    position: absolute;
    top: 0;
    right: 0;
  }
  .position-absolute-bottom-0--lg {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/*------------------------------------
  Width
------------------------------------*/
.min-width-3 {
  min-width: 1rem;
}

.min-width-35 {
  min-width: 7rem;
}

.max-width-3 {
  max-width: 1rem;
}

.max-width-4 {
  max-width: 1.5rem;
}

.max-width-5 {
  max-width: 2rem;
}

.max-width-6 {
  max-width: 2.5rem;
}

.max-width-7 {
  max-width: 3rem;
}

.max-width-9 {
  max-width: 4rem;
}

.max-width-10 {
  max-width: 5rem;
}

.max-width-12 {
  max-width: 6rem;
}

.max-width-14 {
  max-width: 7rem;
}

.max-width-18 {
  max-width: 9rem;
}

@media (min-width: 576px) {
  .max-width-sm-40 {
    max-width: 20rem;
  }
}

/*------------------------------------
  Height
------------------------------------*/
.height-100vh {
  height: 100vh;
}

.height-400 {
  height: 25rem;
}

.height-550 {
  height: 34.375rem;
}

.height-600 {
  height: 600px;
}

.min-height-280 {
  min-height: 17.5rem;
}

@media (min-width: 576px) {
  .height-100vh--sm {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .height-100vh--md {
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .height-15vh--lg {
    height: 15vh;
  }
  .height-85vh--lg {
    height: 85vh;
  }
  .height-100vh--lg {
    height: 100vh;
  }
  .height-600--lg {
    height: 600px;
  }
}

@media (min-width: 1200px) {
  .height-100vh--xl {
    height: 100vh;
  }
}

.cp-1 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.cp-1-top {
  padding-top: 2rem;
}

.cp-1-bottom {
  padding-bottom: 2rem;
}

.cp-2 {
  padding-top: 4.0625rem;
  padding-bottom: 4.0625rem;
}

.cp-2-top {
  padding-top: 4.0625rem;
}

.cp-2-bottom {
  padding-bottom: 4.0625rem;
}

.cp-3 {
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
}

.cp-3-top {
  padding-top: 8.125rem;
}

.cp-3-bottom {
  padding-bottom: 8.125rem;
}

.cp-4 {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}

.cp-4-top {
  padding-top: 12.5rem;
}

.cp-4-bottom {
  padding-bottom: 12.5rem;
}

.cp-5 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.cp-5-top {
  padding-top: 15rem;
}

.cp-5-bottom {
  padding-bottom: 15rem;
}

.cp-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.cp-0-top {
  padding-top: 0;
}

.cp-0-bottom {
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .cp-3--sm {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .cp-3-bottom--sm {
    padding-bottom: 8.125rem;
  }
  .cp-4-top--sm {
    padding-top: 12.5rem;
  }
  .cp-5--sm {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}

@media (min-width: 768px) {
  .cp-2-bottom-0--md {
    padding-bottom: 0;
  }
  .cp-3--md {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .cp-3-top--md {
    padding-top: 8.125rem;
  }
  .cp-3-bottom--md {
    padding-bottom: 8.125rem;
  }
  .cp-4-top--md {
    padding-top: 12.5rem;
  }
  .cp-5--md {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .cp-5-top--md {
    padding-top: 15rem;
  }
}

@media (min-width: 992px) {
  .cp-1--lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .cp-3--lg {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .cp-3-top--lg {
    padding-top: 8.125rem;
  }
  .cp-3-bottom--lg {
    padding-bottom: 8.125rem;
  }
  .cp-4--lg {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .cp-4-top--lg {
    padding-top: 12.5rem;
  }
  .cp-4-bottom--lg {
    padding-bottom: 12.5rem;
  }
  .cp-5--lg {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .cp-5-top--lg {
    padding-top: 15rem;
  }
  .cp-0--lg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .cp-0-top--lg {
    padding-top: 0;
  }
  .cp-0-bottom--lg {
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .cp-3-bottom--xl {
    padding-bottom: 8.125rem;
  }
  .cp-4-top--xl {
    padding-top: 12.5rem;
  }
}

/*------------------------------------
  Font Size
------------------------------------*/
.font-size-13 {
  font-size: 0.8125rem;
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-18 {
  font-size: 1.125rem;
}

.font-size-32 {
  font-size: 2rem;
}

@media (max-width: 991px) {
  .font-size-48--md-down {
    font-size: 3rem;
  }
}

/*------------------------------------
  Font Weight
------------------------------------*/
.font-weight-medium {
  font-weight: 500;
}

/*------------------------------------
  Text Colors
------------------------------------*/
.text-light-70 {
  color: rgba(255, 255, 255, 0.7);
}

.text-light-70[href]:hover {
  color: #fff;
}

.text-secondary[href]:hover {
  color: #263F8F !important;
}

/*------------------------------------
  Z-Index
------------------------------------*/
.z-index-2 {
  z-index: 2;
}

.z-index-minus-1 {
  z-index: -1;
}

/*------------------------------------
  Animate.css
------------------------------------*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
            transform: translate3d(0, 25%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
            transform: translate3d(0, 25%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
}

/*------------------------------------
  Cubeportfolio
------------------------------------*/
.u-cubeportfolio .u-cubeportfolio__item {
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  margin: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 1rem;
}

.u-cubeportfolio .u-cubeportfolio__item:hover {
  color: #151b26;
}

.u-cubeportfolio .u-cubeportfolio__item.cbp-filter-item-active {
  color: #263F8F;
  background-color: transparent;
  border: transparent;
}

.u-cubeportfolio .cbp-l-loadMore-stop {
  pointer-events: none;
}

.u-cubeportfolio .cbp-caption-zoom .cbp-caption-activeWrap {
  background-color: rgba(255, 255, 255, 0.7);
}

/* Arrow */
.u-cubeportfolio .cbp-nav-controls {
  position: static;
}

.u-cubeportfolio .cbp-nav-prev,
.u-cubeportfolio .cbp-nav-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #fff;
  background-color: #646f79;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.u-cubeportfolio .cbp-nav-prev:hover,
.u-cubeportfolio .cbp-nav-next:hover {
  color: #fff;
  background-color: #263F8F;
}

.u-cubeportfolio .cbp-nav-prev {
  left: 0.5rem;
}

.u-cubeportfolio .cbp-nav-next {
  right: 0.5rem;
}

/* Pagination */
.u-cubeportfolio .cbp-nav-pagination {
  bottom: -3rem;
}

.u-cubeportfolio .cbp-nav-pagination-item {
  width: 1rem;
  height: 1rem;
  background-color: #8c98a4;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  transition: 0.3s;
}

.u-cubeportfolio .cbp-nav-pagination-active {
  background-color: transparent;
  border-color: #8c98a4;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

/*------------------------------------
  Custombox
------------------------------------*/
.custombox-lock {
  overflow: auto;
}

/*------------------------------------
  Dzsparallaxer
------------------------------------*/
.dzsparallaxer {
  color: inherit;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
.u-fancybox-theme .fancybox-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-fancybox-theme .fancybox-slide--iframe .fancybox-content {
  position: static;
}

.u-fancybox-theme .fancybox-bg {
  background-color: #151b26;
}

.fancybox-controls--canzoomIn .fancybox-placeholder,
.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: inherit;
}

.fancybox-slide.has-animation {
  display: block;
}

.fancybox-is-sliding .fancybox-slide.has-animation,
.fancybox-slide--current.has-animation,
.fancybox-slide--next.has-animation,
.fancybox-slide--previous.has-animation {
  display: none;
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated,
.fancybox-slide--current.has-animation.animated,
.fancybox-slide--next.has-animation.animated,
.fancybox-slide--previous.has-animation.animated {
  display: block;
}


.fancybox-content .designer .preview-container {
  margin: 0 auto;
  border: none !important;
}
.fancybox-content .designer .preview-container .preview {
  display: block !important;
  margin: 10% auto;
  transform: scale(1.3);
  max-width: 76%;
}
// .fancyboxCustom{
//   width: 1000px;
//   overflow: hidden;
// }
// .fancyboxCustom .designer .preview-container {
//   margin: 0 auto;
//   border: none !important;
// }


.u-blur-30 {
  -webkit-filter: blur(30px);
          filter: blur(30px);
}

.fancyboxCustom {
  width: 1000px;
  overflow: hidden!important;;
  .designer {
    .preview-container {
      margin: 0 auto;
      border: none !important;
    }
  }
}
.fancyboxCustom {
  .designer {
    .preview-container {
      .preview {
        display: block !important;
        margin: 10% 22%;
        transform: scale(1.3);
        max-width: 76%;
        .preview-image{
          background: white;
        }
      }
    }
  }
}
/*------------------------------------
  Slick
------------------------------------*/
[data-lazy] {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.slick-cloned [data-scs-animation-in] {
  opacity: 0;
}

// .u-slick {
//   background: url(../svg/preloaders/slick-carousel-preloader-primary.svg) no-repeat 50% 50%;
// }

.u-slick .js-next,
.u-slick .js-prev {
  opacity: 0;
}

.u-slick.slick-initialized {
  background: none;
}

.u-slick.slick-initialized .js-slide,
.u-slick.slick-initialized .js-thumb {
  visibility: visible;
  height: auto;
  overflow: visible;
}

.u-slick.slick-initialized .js-next,
.u-slick.slick-initialized .js-prev {
  opacity: 1;
}

.u-slick .js-slide,
.u-slick .js-thumb {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.u-slick .js-slide:first-child,
.u-slick .js-thumb:first-child {
  height: auto;
}

/*------------------------------------
  Typed.js
------------------------------------*/
.typed-cursor-none .typed-cursor {
  display: none;
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
          animation: blink 0.7s infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*------------------------------------
  Skippy
------------------------------------*/
.u-skippy {
  display: none;
}

#home {
  .collapsed {
    padding: 0 !important;
  }

  .list {
    h3 {
      margin: 0
    }
  }
  .PBCfM {
    padding: 0;
    @media screen and (max-width: 812px) {
      text-align: center;
    }
    .inner {
      padding: 0
    }
  }
}
#wholesale-landing {
  .PBCfM {
    .vertical {
      .btn-light {
        background-color: #263F8F
      }
    }
  }
}

@media (max-width: 812px) {
  .slideshow .slide-container .slide {
    display: block;
    .btn.btn-primary {
      max-width: 100%;
    }
  }
  .modal .modal-container {
    margin-top: 0;
    max-height: 95%;
  }
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

    footer {  
        padding-bottom: 100px;

    }
}}