/* slideshow
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.slideshow {
  overflow:hidden;
  position:relative;
  .slide-container {
    position: relative;
    left:0%;
    transition: left .8s, height .8s;
    white-space: nowrap;
    display:flex;
    .carousel-item {
      @media screen and (max-width: 812px) {
        height: 300px;
      }
    }
    .slide {
      display:flex;
      flex-direction:column;
      justify-content:center;
      width:100%;
      flex-basis:100%;
      flex-shrink: 0;
      border:0;
      padding:0;
      margin:0;
      white-space: normal;
    }
    .carousel-caption {
      @media screen and (max-width: 812px) {
        display: flex !important;
        width:70% !important;
        top:80px !important;
        left:15% !important;
        padding: 10px;
        h2 {
          font-size: 20px;
          margin: 10px;
        }
        p {
          font-size: 12px;
          text-align: justify;
          display: inline;
        }
      }
    }
  }
  .img-slide {
    padding-bottom:50%;
  }
}
