/*
----------------
utilities.scss
----------------

This is a collection of commonly used classes and mixins.

Classes can be used directly in markup as functional css.

Mixins can be used within other classes -- for example:

.my-button {
  background-color: blue;
  @include drop-shadow;
}
*/

/* Hide Elements
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.hide {
  display: none;
}
// .hide-s, .hide-m, .hide-l, .hide-xl   <-- Woot!
// ––––––––––––––––––––––––––––––––––––––––––––––––––
// You can explicity hide any element at any individual breakpoint
// Example:
// <!-- No desktop nav on small screens / No Hamburger nav on big screens. -->
// <div class="desktop-nav hide-s hide-m"></div>
// <div class="hamburger-nav hide-l hide-xl"></div>
@media only screen and (max-width: #{$s_max}) {
  .hide-s { display: none }
}
@media only screen and (min-width: #{$m_min}) and (max-width: #{$m_max}) {
  .hide-m { display: none }
}
@media only screen and (min-width: #{$l_min}) and (max-width: #{$l_max}) {
  .hide-l { display: none }
}
@media only screen and (min-width: #{$xl_min}) {
  .hide-xl { display: none }
}

/* Align Text
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

/* Lorem Ipsum
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.lorem:after {
  content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus optio non aperiam dicta, ullam tempore sequi eveniet, ratione modi voluptate. Quia blanditiis neque sapiente obcaecati aspernatur, maxime iusto necessitatibus porro. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus optio non aperiam dicta, ullam tempore sequi eveniet, ratione modi voluptate. Quia blanditiis neque sapiente obcaecati aspernatur, maxime iusto necessitatibus porro.";
}

/* Drop Shadow
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@mixin drop-shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.drop-shadow { @include drop-shadow }

/* Padded Sections
–––––––––––––––––––––––––––––––––––––––––––––––––– */
$section-padding: 40px;

.padded {
  padding-top: $section-padding;
  padding-bottom: $section-padding;
}

.bottom-padded {
  padding-bottom: $section-padding;
}

.top-padded {
  padding-top: $section-padding;
}

/* Responsive Square
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.square {
  width: 100%;
  padding-bottom: 100%;
}
