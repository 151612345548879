/*
----------------
global.scss
----------------
This is for styles which are to be applied globally. That means things like
global typography and layouts, etc.

It is NOT for one-offs related only to a specific page. For those, please edit
the styles in the SCSS file for React component itself.

Some sections below such as Typography and Forms were borrowed from Skeleton.
http://getskeleton.com
*/

/* Layout
–––––––––––––––––––––––––––––––––––––––––––––––––– */
// Make sure the React root elements are full screen
.root,
.root-wrapper {
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh
}

* { // Don't add padding to width fer funk sake!
  box-sizing: border-box;
}

/* Force sticky footer
–––––––––––––––––––––––––––––––––––––––––––––––––– */
body, #root, .root-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  main {
    flex: 1;
    max-width: 100%;
  }
}

/* General UI
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.image-cover {
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
}

.image-contain {
  background-size:contain;
  background-repeat:no-repeat;
  background-position:center;
}

a {
  &:hover, &:focus {
    text-decoration: none;
  }
}

.visible-on-focus {
  &:not(:focus) {
    left: -9999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
  }
}