// Theme colors for Admin
$acolor1: #3164a2;
$acolor_danger: #d9534f;
$acolor2: #ddd;

@import '/widgets/Image.scss';
@import '/widgets/Icons.scss';
@import '/widgets/Modal.scss';
@import 'ContextPane.scss';

// for dev/debugging
.react-hot-loader-error-overlay {
  z-index: 9999999;
}

/* admin-login
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.admin-login {
  background: #28224A url(./images/admin-bg.jpg) no-repeat center center fixed;
  background-size: cover;
  min-height: 70vh;
  display:flex;
  justify-content:center;
  flex-direction: column;
  .login-form {
    position: relative;
    z-index: 2;
    background: rgba(255, 255, 255, 0.75);
    max-width: 360px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25), 0 5px 5px 0 rgba(0, 0, 0, 0.25);
    input, button {
      width: 100%;
      border-radius: 3px;
      margin: 2px;
      border: 0;
    }
    .Input {
      padding: 3px;
    }
    a {
      display: inline-block;
      margin-top: 10px;
    }
  }
}

/* admin-bar
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.admin-bar {
  display: none;
}
.root-wrapper.is-admin {
  .fluid-body {
    margin-top: $admin_bar_height;
  }

  // main {
  //   margin-top: $admin_bar_height + $header_height;
  // }

  .modal-container {
    padding-top: calc(5% + #{$admin_bar_height});
  }

  .admin-bar {
    position: fixed;
    z-index: 90;
    display: flex;
    top: 0;
    left: 0;
    height: $admin_bar_height;
    width: 100%;
    padding: 4px;
    background-color: #000;
    overflow:hidden;
    align-items: center;



    .col {
      padding-top: 0;
      padding-bottom: 0;
    }



    button.material-icons {
      background: transparent;
      font-size: 34px;
      vertical-align: bottom;
      color:white;
    }

    .settings-toggle {
      display: inline-block;
      width: 28px;
      margin-right: 20px;
    }


    .launchpad-logo {
      margin-left: 12px;
      color: white;
      font-size: 25px;
    }

    .toolbar {
      margin: 0 auto;
      display:flex;
      align-items: center;
      justify-content: center;
      .admin-tools {
        display:flex;
        align-items: center;
      }
    }
    $buttonhint: darken($bcolor1, 13%);
    button, .btn {
      border: 0;
      background: darken($bcolor1, 10%);
      padding:5px 10px;
      margin: 0;
      border-radius: 0;
      height: $admin_bar_height;
      border-left: 1px solid $buttonhint;
      min-width: 40px;
      color: #ccc;

      &:not(.active):hover {
        background: $buttonhint;
      }

      &.active {
        background: $bcolor1;
        color: white;
      }

      &:last-of-type{
        border-right: 1px solid $buttonhint;
      }
      span {
        line-height: inherit;
      }
    }

    span {
      line-height: $admin_bar_height;
      color: #EEE;
    }
  }
  .btn, button {
    border: 0;
  }
}

/* media library
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.media-library {
  max-height:0vh;
  position:fixed;
  top:$admin_bar_height;
  width:100%;
  background:white;
  margin:0 -4px;
  transition: max-height .5s, padding-right .5s;
  overflow:hidden;
  z-index:98;

  &.open {
    max-height:100vh;
    .media-container {
      max-height:80vh;
      overflow:auto;
    }
  }

  .btn, button {
    font-weight:400;
    margin-left:1%;
  }

  .media-container {
    overflow:hidden;
    padding:1%;
    .thumbnail {
      text-align:right;
      cursor:pointer;
      width:13%;
      border: 2px solid transparent;
      padding-bottom:8%;
      margin-right:1%;
      margin-bottom:1%;
      background-size:contain;
      background-color:#ddd;
      background-repeat:no-repeat;
      display:inline-block;
      background-position:center;
      &.active {
        border-color:$acolor1;
      }
    }
  }
}



/* admin-panel
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.admin-panel {
  max-height:0vh;
  position:fixed;
  top:$admin_bar_height;
  width:100%;
  background:white;
  margin:0 -4px;
  transition: max-height .5s;
  overflow:hidden;
  z-index:98;

  &.open {
    max-height:100vh;
    .admin-options {
      max-height:80vh;
      overflow:auto;
    }
  }

  label {
    display: inline-block;
    width:150px;
  }

  input {
    border-bottom:2px solid $acolor1;
    width:60%;
  }

  .btn, button {
    font-weight:400;
    margin-left:1%;
  }

  .admin-options {
    overflow:hidden;
    padding:1%;

    .accordion {
      margin-bottom: 40px;
    }
    .setting {
      display:inline-block;
      width:40%;
    }
  }
}

/* admin buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.btn, button {
  .material-icons {
    vertical-align: top;
  }
  &.material-icons {
    //color: $acolor1;
    padding:0;
    border:0;
    font-size:12px;
    border-radius:5px;
    &:hover, &:focus {
      //background: transparent;
      opacity:.8
    }
  }
}

.btn.danger, button.danger {
  background: $acolor_danger;
  color: white;
  &:hover {
    background: darken($acolor_danger, 15%);
  }
}

/* snips
–––––––––––––––––––––––––––––––––––––––––––––––––– */

// visual fix for odd nesting as a symptom of execCommand()
h1, h2, h3, h4, h5, h6 {
  .snip p {
    padding: 0px;
    margin: 0px;
  }
}

.snip-container {
  &.empty {
    p, h1, h2, h3, ul, ol {
      opacity: .5;
    }
  }
  position:relative;
  border: 1px dashed transparent;
  &.admin {
    &:hover, &:focus {
      outline: 0;
      background: rgba(0,0,0,.05);
      border: 1px dashed #ddd;
    }
    .overlay {
      background: rgba(0,0,0,.05);
      border: 1px dashed #ddd;
      position: absolute;
      top: -10px;
      left: -10px;
      bottom: -10px;
      right: -10px;
    }
  }

  p {
    color: inherit;
  }

  &.inline {
    p {
      padding: 0;
      margin: 0;
    }
  }
}

// .snip.editable {
//   border: 1px dashed rgba(100, 100, 100, .2);
//   margin: -10px;
//   padding: 10px;
// }


/* admin utility classes
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.progress-bar {
  width:98%;
  background: #ddd;
  border-radius:4px;
  height:10px;
  box-shadow: inset 0 0 2px rgba(0,0,0,.2);
  overflow:auto;
  margin:1%;
  .current {
    height:10px;
    border-radius:2px;
    background:blue;
    transition:width .3s;
  }
}




/* forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.admin-form {
  padding: 15px;
  h1, h2, h3, h4, h5 {
    margin: 40px -10px 20px;
    padding: 10px;
    box-shadow: 0 5px 5px -2px rgba(0,0,0,.2)
  }
}

.Select {
  margin: 5px 0;
  input {
    padding:8px 0 12px;
    margin:0;
    border:0;
  }
}

.Input {
  & > div {
    width: 100% !important;
  }
}


/* Collapsible Panel
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.collapsible-panel {
  .wrapper {
    transition: max-height .3s;
    overflow: hidden;
    box-sizing: border-box;
  }
}



// TODO: responsiveness for admin interface
// hiding admin header at mobile widths
@media (max-width: 480px) {
  .root-wrapper.is-admin {
    padding-top: 0;
    .admin-bar {
      display: none;
    }
  }
}
