/* Footer
–––––––––––––––––––––––––––––––––––––––––––––––––– */
footer {
  background: #333;
  padding: 80px 20px;
  padding-bottom: 0;
  a {
    color:white;
  }
  color: white;

  ul, li {
    list-style: none;
  }
  ul {
    padding: 0;
  }
  .pre-footer {
    padding: 70px 0;
    padding-top: 20%;
    margin-bottom: 70px;
    border-bottom: 1px solid white;
    text-align: center;
    .btn {
      margin-top: 30px;
    }
  }

  .footer-lists {
    .logo {
      margin-bottom: 60px;
      .editable-image {
        min-height: 50px;
        max-width: 50%;
      }
    }
    // display:flex;
    // &>*{
    //   flex:1;
    // }
    // ul {
    //   li {
    //     &:first-child {
    //       margin-bottom: 7px;
    //     }
    //   }
    // }
    .contact {
      text-align: center;
      & > div {
        margin-bottom: 10px;
      }
    }
    padding-bottom: 40px;
  }

  .social {
    a {
      //background-color:#fff;
      //color:$bcolor1;
      text-align:center;
      border-radius:50%;
      font-size:15px;
      // line-height:30px;
      // width:30px;
      width: 30px;
      //margin:3px;
      //margin-left: -10px;
      transition: background-color .3s;
      // &:hover {
      //   background-color: #aaa;
      // }
      span {
        display: inline-block;
        min-width: 20px;
        text-align: center;
        margin-right: 7px;
      }
    }
  }

  .footer-links {
    h3 {
      font-size: 16px;
      font-weight: bold;
    }
    margin: 0 -15px;
  }

  .sub-footer {
    margin-top: -1px;
    font-weight: 300;
    padding: 15px;
  }

  .copyright {
    padding-top: 80px;
    font-size: .7em;
  }

  @media (max-width: 768px) {
    .footer-links {
      h3 {
        font-size: 1.3em;
      }
      li {
        position: relative;
        margin: 0;
        margin-left: -2px;
        padding: 0;
        padding-right: 0;
      }
    }
  }
}
