/* Context Pane
======================================= */
$context_pane_width: 315px;

.context-pane {
  position:fixed;
  background: #111;
  z-index: 90;
  width: 0;
  height: calc(100vh - #{$admin_bar_height});
  top: $admin_bar_height;
  left: 0;
  transition: width .5s;
  overflow:hidden;


  &.open {
    width: $context_pane_width;
  }

  h3 {
    font-size: 1em;
  }
  ul {
    padding: 0;
  }
  .collapsible-panel {
    background: white;
  }
  .admin-options {
    border-right:1px solid #777;
    height: 100%;
    overflow:auto;
    width: $context_pane_width;
    .accordion {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      .sidebar-settings {
        transform: rotate(0deg) !important;
      }
    }
    .accordion .tab {
      padding: 15px 15px;
      background: #111;
      color: #ddd;
      margin:0;
      font-size: 1.2rem;
      opacity: 1;
      border: 0 solid transparent;
      transition: opacity .3s, border .3s;
      cursor:pointer;
      display: flex;
      align-items: center;

      span {
        &.fa-chevron-down {
          font-size: .8em;
        }
        width: 1.7em;
        display:inline-block;
        padding: 5px 10px;
        margin-right: 7px;
        vertical-align: bottom;
        color: #efefef;
        &.fa-cog, &.fa-chevron-down {
          transform: rotate(-90deg);
        }
        transition: transform .5s;
      }
      &.open {
        border-left: 4px solid #bbb;
        span {
          transform: rotate(0deg)
        }
      }
      &:hover {
        opacity: .9;
      }
      &.sign-out {
        margin-top: auto;
      }
    }
    .sidebar-settings {
      h4 {
        font-size:1.7rem;
        margin-bottom: 5px;
        margin-top:15px;
        border-bottom: 1px solid #ddd;
      }
      .inner > div {
        margin-bottom: 30px;
        padding-bottom: 30px;
      }
    }

    .sidebar-pages, .sidebar-group {
      // a {
      //   display: block;
      //   width: 100%;
      //   padding: 20px;
      //   border-bottom: 1px solid transparent;
      //   &:hover {
      //     background: rgba(0,0,0,.05);
      //     border-bottom: 1px solid rgba(0,0,0,.1);
      //   }
      //}
      background: #111;
      .collapsible-panel {
        background: transparent;
      }
      .inner {
        background: transparent;
        padding: 0;
        .inner {
          padding: 0;
          margin: 0;
          border: 0;
        }
      }
    }

    .sidebar-group {
      .inner {
        border-left: 4px solid #bbb;
      }
    }


    .inner {
      padding: 10px 20px 30px;
      .editSnip {
        .heading {
          margin-bottom: 0;
        }
        .snip {
          background: #eee;
          padding: 5px;
        }
      }
    }
  }
}

#rootWrapper {
  transition: padding-left .5s;
  header {
    transition: padding-left .5s, margin .5s;
  }
  .admin-bar {
    transition: margin-left .5s;
  }
}

#rootWrapper.context-open {
  padding-left:$context_pane_width;
  // header {
  //   padding-left:$context_pane_width;
  //   margin-left:-$context_pane_width;
  // }
  .shadow-nav {
    padding-left:$context_pane_width;
  }
  .media-library {
    padding-right: $context_pane_width;
  }
  // .collapsible-nav {
  //   padding-left:$context_pane_width;
  // }
  .modal {
    padding-left:$context_pane_width;
  }
}
