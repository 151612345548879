/* Collapsible Panel
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.collapsible-panel {
  &:focus {
    border: 0;
    outline: 0;
  }
  .wrapper {
    transition: max-height .3s;
    overflow: hidden;
    box-sizing: border-box;
  }
}
