/* dynamic list
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.dynamic-list {
  .new-item {
    .Input {
      display:inline-block;
      & > div {
        width: auto !important;
      }
    }
    button {
      vertical-align:middle;
    }
  }
}
